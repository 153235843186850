import axios from 'axios';
import React from 'react';
import config from '../../config';
import defaultProfile from '../../assets/default-profile.jpg' ;
import logo from '../../assets/logo.png';

function Navbar(props) {
    const [profileCollapse, setProfileCollapse] = React.useState(false);
    const [user, setUser] = React.useState({});

    React.useEffect(() => {
        axios({
            url: `${config.url}/accounts`,
            method: "GET"
        })
        .then(data => {
            let userID = sessionStorage.getItem("token");
            const rawData = data.data;
            let fetchedUser = rawData.filter(user => user.userID == userID);
            if (fetchedUser.length > 0) {
                setUser(fetchedUser[0]);
            }
        })
    }, []);

    return (
        <nav className="fixed top-0 z-50 w-full bg-green-500 text-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start rtl:justify-end">
                        <button onClick={() => props.toggleBtn()} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="text-white inline-flex items-center p-2 text-sm rounded-lg sm:hidden hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                            </svg>
                        </button>
                        <a href="" className="flex ms-2 md:me-24">
                            <div className='flex items-center'>
                                <img src={logo} className='w-14 h-14' alt="" />
                                <h1 className='text-green-200 text-3xl font-semibold'>TamaTrack</h1>
                            </div>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <div className='flex items-end flex-col justify-center'>
                            <h1 className='text-lg font-semibold'>{user["name"]}</h1>
                            <h1 className='text-sm'>{user["type"]}</h1>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center ms-3">
                                <div>
                                    <span className="sr-only">Open user menu</span>
                                    <img className="w-10 h-10 rounded-full" src={!user.image ? defaultProfile:user.image} alt="user photo" />
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar