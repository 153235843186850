import React from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import booleanRenderer from "../components/booleanRenderer.jsx";
import actionButtons from "../components/actionButtons.jsx";
import axios from 'axios';
import { io } from 'socket.io-client';
import config from '../../config.js';
import { Timestamp } from "@firebase/firestore";

function Credentials(props) {
    const [rowData, setRowData] = React.useState([]);
    const [searchFilter, setSearchFilter] = React.useState("");
    const gridRef = React.useRef();
    const [colDefs, setColDefs] = React.useState();
    const [logColDefs, setLogColDefs] = React.useState();
    const [isBulkEditingChecked, setBulkEditingChecked] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [showStatus, setShowStatus] = React.useState(false)
    const [bulkEditLoading, setBulkEditLoading] = React.useState(false)
    const [notifConfig, setNotifConfig] = React.useState([]);
    const [notificationsData, setNotificationsData] = React.useState([]);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [currentCredential, setCurrentCredential] = React.useState("");
    const [credentialsData, setCredentialsData] = React.useState([]);
    const [openBulkDeleteModal, setOpenBulkDeleteModal] = React.useState(false);
    const [openLog, setOpenLog] = React.useState(false);
    const [isDoneFetching, setIsDoneFetching] = React.useState(false);
    // const socket = io.connect(config.url);

    React.useEffect(() => {
        axios({
            url: `${config.url}/credentials`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            rawData.forEach(element => {
                element.status = {
                    id: element.id,
                    status: element.status
                }
            });
            setRowData(rawData)
            setIsDoneFetching(true)
        })

        axios({
            url: `${config.url}/notifConfig`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotifConfig(rawData)
        })

        axios({
            url: `${config.url}/notifications`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotificationsData(rawData)
        })
    }, [])

    React.useEffect(() => {
        setColDefs([
            { 
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 40
            },
            { field: "studentNumber", headerName: "Student No.", maxWidth: 120 },
            { field: "fullName", headerName: "Full Name", flex: 1},
            { field: "document", headerName: "Requested Document", flex: 1},
            { field: "status", headerName: "Status", cellStyle: {"padding": 0}, flex: 1,
                cellRenderer: props => {
                    return (
                        <div className="w-full h-full">
                            <select name="" onChange={data => {setCurrentCredential(props.value.id); changeStatus(data.target.value, props.value.id, rowData); }} className='border-none w-full h-full'>
                                <option value="Pending" selected={props.value.status.toLowerCase() == "pending"}>Pending</option>
                                <option value="Declined" selected={props.value.status == "Declined"}>Declined</option>
                                <option value="Processing" selected={props.value.status == "Processing"}>Processing</option>
                                <option value="Ready for Pickup" selected={props.value.status == "Ready for Pickup"}>Ready for Pickup</option>
                                <option value="Completed" selected={props.value.status.toLowerCase() == "completed"}>Completed</option>
                            </select>
                        </div>
                    )
                }
            },
            { field: "date", headerName: "Requested Date", flex: 1, 
                cellRenderer: props => {
                    if (props.value != null) {
                        let date = new Date(props.value.seconds*1000);
                        date = date.toString().split(" ")
                        return <h1> {`${date[1]} ${date[2]}, ${date[3]}`}</h1>
                    } else {
                        return <h1></h1>
                    }
                }
            },
            { field: "id", headerName: "Last Update", flex: 1,
                cellRenderer: props => {
                    const credentialID = props.value;
                    const data =rowData.filter(data => data.id == credentialID)[0]
                    if (data.lastUpdate != null) {
                        let date = new Date(data.lastUpdate.seconds*1000);
                        date = date.toString().split(" ")
                        return <button onClick={() => {setCurrentCredential(credentialID); setOpenLog(true)}}>{`${date[1]} ${date[2]}, ${date[3]}`}</button>
                    } else {
                        return <h1></h1>
                    }
                }
            },
            { field: "id", headerName: "Actions", 
                cellRenderer: props => {
                    return (
                        <div className="flex items-center gap-1 w-full h-full">
                            <button type="button" onClick={() => {setCurrentCredential(props.value); setOpenDeleteModal(true)}} className="w-8 h-8 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            </button>
                        </div>
                    )
                }
            , },
        ])
        
        setLogColDefs([
            { field: "message", headerName: "Message", flex: 2},
            { field: "date", headerName: "Date", flex: 1, 
                cellRenderer: props => {
                    if (props.value != null) {
                        return <h1> {getDate(props.value)}</h1>
                    } else {
                        return <h1></h1>
                    }
                }
            },
        ])
    }, [rowData])

    function tConvert (time) {
        const [hourString, minute] = time.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }

    const getDate = (dateCreated) => {
        let date = new Date(dateCreated.seconds*1000);
        date = date.toString().split(" ")
        return <h1> {`${date[0]}, ${date[1]} ${date[2]}, ${date[3]} ${tConvert(date[4])}`}</h1>
    }

    React.useEffect(() => {
        const fetchCredentialsData = () => {
            const credentialsData = rowData.filter(el => {
                return el.id == currentCredential;
            })
            setCredentialsData(credentialsData[0] != null && credentialsData[0]["changeLog"] != null ? credentialsData[0]["changeLog"] : []);
        }
        fetchCredentialsData();
    }, [currentCredential]);

    // React.useEffect(() => {
    //     socket.on("credentials_data_change", (data) => {
    //         setRowData(data);
    //     })
    // }, [socket]);

    const deleteData = (e) => {
        e.preventDefault();
        setDeleting(true);
        axios.post(`${config.url}/delete`, {collection: "credentials", id: currentCredential})
        .then(() => {
            const remainingData = rowData.filter(row => row.id != currentCredential);
            setRowData(remainingData);
            // socket.emit("send_credentials_data", remainingData);
            setDeleting(false);
            setOpenDeleteModal(false);
        })
        .catch((error) => {
            console.error('Error deleting credentials:', error);
        });
    }

    const changeStatus = (status, id, data) => {
        const newData = data;
        setBulkEditLoading(true);
        try {
            const index = newData.findIndex(index => {
                return index.id == id;
            });
            newData[index].status = {
                id: id,
                status: status
            };

            switch(status) {
                case "Declined":
                    notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Credential Request Declined").forEach(data => {
                        axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}).then(() => {
                            setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}]);
                            // socket.emit("send_notification_data", notificationsData);
                        });
                        axios.get(`${config.url}/students`).then(el => {
                            const student = el.data.filter(e => e.id == newData[index].studentID);
                            student.forEach(e => {
                                if (e.fCMToken != null) {
                                    axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                                }
                            })
                        })
                    });
                    break;
                case "Processing":
                    notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Credential Request Processing").forEach(data => {
                        axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}).then(() => {
                            setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}]);
                            // socket.emit("send_notification_data", notificationsData);
                        });
                        axios.get(`${config.url}/students`).then(el => {
                            const student = el.data.filter(e => e.id == newData[index].studentID);
                            student.forEach(e => {
                                if (e.fCMToken != null) {
                                    axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                                }
                            })
                        })
                    });
                    break;
                case "Ready for Pickup":
                    notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Credential Request Ready for Pickup").forEach(data => {
                        axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}).then(() => {
                            setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}]);
                            // socket.emit("send_notification_data", notificationsData);
                        });
                        axios.get(`${config.url}/students`).then(el => {
                            const student = el.data.filter(e => e.id == newData[index].studentID);
                            student.forEach(e => {
                                if (e.fCMToken != null) {
                                    console.log(e.fCMToken)
                                    axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                                }
                            })
                        })
                    });
                    break;
                case "Completed":
                    notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Credential Request Completed").forEach(data => {
                        axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}).then(() => {
                            setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: newData[index].studentID, isSeen: false}]);
                            // socket.emit("send_notification_data", notificationsData);
                        });
                        axios.get(`${config.url}/students`).then(el => {
                            const student = el.data.filter(e => e.id == newData[index].studentID);
                            student.forEach(e => {
                                if (e.fCMToken != null) {
                                    axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                                }
                            })
                        })
                    });
                    break;
            }

            axios.post(`${config.url}/update/credentials/`+id, {"changeLog": [{message: `${props.currentUser["name"]} changed the status for this request to "${status}".`, date: Timestamp.fromDate(new Date())}, ...credentialsData], "lastUpdate": Timestamp.fromDate(new Date()), "status": status, }).then(() => {
                console.log(Timestamp.fromDate(new Date()));
                const index = rowData.findIndex(index => {
                    return index.id == id;
                });
                newData[index] = {...newData[index], lastUpdate: Timestamp.fromDate(new Date()), changeLog: [{message: `${props.currentUser["name"]} changed the status for this request to "${status}".`, date: Timestamp.fromDate(new Date())} , ...(newData[index]["changeLog"] != null ? newData[index]["changeLog"] : [])]}
                setRowData(newData);
                setCredentialsData(newData[index]["changeLog"]);
                // socket.emit("send_credentials_data", newData);
                axios({
                    url: `${config.url}/students`,
                    method: "GET"
                })
                .then(data => {
                    let rawData = data.data;
                    rawData.forEach(element => {
                        element.action = element.userID;
                        element[newData[index].document] = (status.toLowerCase() == "completed");
                        element.fullName = `${element.firstName} ${element.lastName}`;
                    });
                    // socket.emit("send_student_data", rawData);
                    gridRef.current.api.deselectAll();
                    setBulkEditLoading(false);
                })
                // socket.emit("send_student_data", newData);
            });
        } catch (error) {
            console.error(error)
        }
    }

    const bulkChangeStatus = (status) => {
        selectedRows.forEach(row => {
            changeStatus(status, row.id, rowData);
        });
    }

    const showStatusSelect = () => {
        const checkedRows = gridRef.current.api.getSelectedRows();
        if (checkedRows.length > 0) {
            setShowStatus(true);
        } else {
            setShowStatus(false);
        }
    }

    const getRowId = React.useCallback((params) => {
        return params.data.id;
    }, []);

    const deleteSelectedData = () => {
        setDeleting(true);
        axios.post(`${config.url}/bulk-delete`, {collection: "credentials", ids: selectedRows} )
        .then(() => {
            const remainingData = rowData.filter(row => !selectedRows.some(e => e.id == row.id));
            setRowData(remainingData);
            // socket.emit("send_credentials_data", remainingData);
            setDeleting(false);
            setOpenBulkDeleteModal(false);
        })
        .catch((error) => {
            console.error('Error deleting students:', error);
        });
    };

    return (
    <div>
        <div className="p-4 sm:ml-64 mt-16">
            <div className='w-full'>
                <h1 className='text-xl font-semibold py-4'>Credentials</h1>
                <hr className='border-black' />
            </div>

            <div className='flex items-center justify-between'>
                <div className="relative w-72 py-5">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input onInput={(value) => {setSearchFilter(value.target.value)}} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                </div>
                <div className={`${selectedRows.length > 0 ? 'flex': 'hidden'} gap-1`}>
                    <button onClick={() => setOpenBulkDeleteModal(true)} className="p-2 w-20 bg-red-600 text-white rounded">Delete</button>
                </div>
                <div className={`py-2 relative ${!showStatus ? "hidden" : ""}`}>
                    <div role="status" className={`${!bulkEditLoading ? "hidden" : ""} absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]`}>
                        <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                    <select name="" onChange={data => bulkChangeStatus(data.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option selected disabled>Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Declined">Declined</option>
                        <option value="Processing">Processing</option>
                        <option value="Ready for Pickup">Ready for Pickup</option>
                        <option value="Completed">Completed</option>
                    </select>
                    {/* <button onClick={() => bulkChangeStatus("pending")} className="mr-2 p-2 bg-yellow-400 text-white rounded">Mark as Pending</button>
                    <button onClick={() => bulkChangeStatus("completed")} className="p-2 bg-green-400 text-white rounded">Mark as Completed</button> */}
                </div>
            </div>

            <div
            className="ag-theme-quartz h-[calc(100vh-18rem)]"
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={[100, 50, 20, 10]}
                    quickFilterText={searchFilter}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    onSelectionChanged={() => {setSelectedRows(gridRef.current.api.getSelectedRows()); showStatusSelect()}}
                    overlayNoRowsTemplate={isDoneFetching ? '<div>No data found.</div>' : '<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]"><svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg><span className="sr-only">Loading...</span></div>'}
                    getRowId={getRowId}
                />
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteData} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openBulkDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenBulkDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenBulkDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteSelectedData} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenBulkDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openLog ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
                <button type='button' className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenLog(false)}></button>
                <div className="relative p-4 w-full max-w-5xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Audit Log
                            </h3>
                            <button onClick={() => setOpenLog(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div
                            className="ag-theme-quartz h-[calc(100vh-20rem)]"
                            >
                                <AgGridReact
                                    rowData={credentialsData}
                                    columnDefs={logColDefs}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationPageSizeSelector={[100, 50, 20, 10]}
                                    hideOVerlay={true}
                                    suppressRowClickSelection={true}
                                    overlayNoRowsTemplate='<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]">
                                                                <svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                                                <span className="sr-only">Loading...</span>
                                                            </div>'
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <button onClick={() => setOpenLog(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                        </div>
                    </div>
                </div>
            </div>

    </div>
    )
}

export default Credentials;
