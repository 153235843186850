import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './templates/pages/Login';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './templates/components/PrivateRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <AuthProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute element={<App />} />}></Route>
            <Route path="/login" element={<Login />}></Route>
          </Routes>
        </BrowserRouter>
    </AuthProvider>
  </div>
);
