import React from 'react';
import Dashboard from './templates/pages/Dashboard';
import SideBar from './templates/components/SideBar'
import Navbar from './templates/components/Navbar';
import BasicEducation from './templates/pages/students/BasicEducation';
import Tertiary from './templates/pages/students/Tertiary';
import Credentials from './templates/pages/Credentials';
import Clearance from './templates/pages/Clearance';
import InfoHub from './templates/pages/InfoHub';
import Notifications from './templates/pages/Notifications';
import Accounts from './templates/pages/Accounts';
import ApproverClearance from './templates/pages/ApproverClearance';
import config from './config';
import axios from 'axios';
import ApproverDashboard from './templates/pages/ApproverDashboard';

function App() {
  const [page, setPage] = React.useState("");
  const [user, setUser] = React.useState({});
  const [toggleClicked, setToggleClicked] = React.useState(true);

    React.useEffect(() => {
        axios({
            url: `${config.url}/accounts`,
            method: "GET"
        })
        .then(data => {
          setPage("dashboard");
          let userID = sessionStorage.getItem("token");
          const rawData = data.data;
          let fetchedUser = rawData.filter(user => user.userID == userID);
          if (fetchedUser.length > 0) {
            setUser(fetchedUser[0]);
          }
        })
    }, []);

    const toggleBtn = () => {
      setToggleClicked(!toggleClicked);
    }

  const pages = {
    "dashboard": user["type"] == "Departmental Approver" ? <ApproverDashboard currentUser={user} /> : <Dashboard />,
    "basicEducation": <BasicEducation currentUser={user} />,
    "tertiary": <Tertiary currentUser={user} />,
    "credentials": <Credentials currentUser={user} />,
    "clearance": user["type"] == "Departmental Approver" ? <ApproverClearance currentUser={user} /> : <Clearance currentUser={user} />,
    "infoHub": <InfoHub />,
    "notifications": <Notifications />,
    "accounts": <Accounts />,
  }

  const pageClick = (pageName) => {
    console.log(pageName)
    setPage(pageName);
  }

  return (
    <div>
      <Navbar toggleBtn={toggleBtn} />
      <SideBar loc={page} page={pageClick} toggleBtn={toggleBtn} toggleClicked={toggleClicked}></SideBar>
      <button style={{all: "unset", width: "100%"}} onClick={() => {setToggleClicked(true)}}>
        {pages[page]}
      </button>
    </div>
  );
}

export default App;
