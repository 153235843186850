import React from 'react'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import booleanRenderer from "../components/booleanRenderer.jsx";
import actionButtons from "../components/actionButtons.jsx";
import axios from 'axios';
import { io } from 'socket.io-client';
import config from '../../config.js';

function Notifications() {
    const [rowData, setRowData] = React.useState([]);
    const [currentNotification, setCurrentNotification] = React.useState("");
    const [searchFilter, setSearchFilter] = React.useState("");
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [notificationData, setNotificationData] = React.useState({"status": "Active"});
    const gridRef = React.useRef();
    const [updating, setUpdating] = React.useState(false);
    const [adding, setAdding] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [isDoneFetching, setIsDoneFetching] = React.useState(false);
    const [colDefs, setColDefs] = React.useState([
        { field: "name", headerName: "Name", flex:2 },
        { field: "message", headerName: "Message", flex:2 },
        { field: "type", headerName: "Type", flex: 1},
        { field: "status", headerName: "Status", maxWidth: 150 },
        { field: "id", headerName: "Actions", 
            cellRenderer: props => {
                return (
                    <div className="flex items-center gap-1 w-full h-full">
                        <button type="button" onClick={() => {setCurrentNotification(props.value); setOpenEditModal(true)}} className="w-8 h-8 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" fill-rule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"></path> </g></svg>
                        </button>
                        <button type="button" onClick={() => {setCurrentNotification(props.value); setOpenDeleteModal(true)}} className="w-8 h-8 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </button>
                    </div>
                )
            }
        , },
    ]);

    // const socket = io.connect(config.url);

    React.useEffect(() => {
        axios({
            url: `${config.url}/notifConfig`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setRowData(rawData)
            setIsDoneFetching(true);
        })
    
    }, [])

    // React.useEffect(() => {
    //     socket.on("notification_config_data_change", (data) => {
    //         setRowData(data);
    //     })
    // }, [socket]);

    React.useEffect(() => {
        const fetchNotificationData = () => {
            const notificationData = rowData.filter(el => {
                return el.id == currentNotification;
            })
            setNotificationData(notificationData[0] != null ? notificationData[0] : {"status": "Active"});
            console.log(notificationData[0] != null ? notificationData[0] : {"status": "Active"})
        }
        fetchNotificationData();
    }, [currentNotification]);

    const getRowId = React.useCallback((params) => {
        const id = params.data.id;
        console.log(id)
        return id;
    }, []);

    const deleteData = (e) => {
        e.preventDefault();
        setDeleting(true);
        axios.post(`${config.url}/delete`, {collection: "notifConfig", id: currentNotification})
        .then(() => {
            const remainingData = rowData.filter(row => row.id != currentNotification);
            setRowData(remainingData);
            // socket.emit("send_notification_data", remainingData);
            setDeleting(false);
            setOpenDeleteModal(false);
        })
        .catch((error) => {
            console.error('Error deleting notifications:', error);
        });
    }

    const updateNotificationData = (e) => {
        e.preventDefault();
        try {
            setUpdating(true)
            let newNotificationData = notificationData
            axios.post(`${config.url}/update/notifConfig/`+notificationData.id, newNotificationData).then(data => {
                const index = rowData.findIndex(index => {
                    return index.id == newNotificationData.id;
                });
                rowData[index] = newNotificationData;
                console.log(rowData);
                setRowData(rowData);
                setUpdating(false)
                setOpenEditModal(false);
                // socket.emit("send_notification_config_data", rowData);
            });
        } catch (error) {
            console.error(error)
        }
    }

    const addNotificationData = (e) => {
        e.preventDefault();
        try {
            setAdding(true)
            console.log(notificationData)
            let newNotificationData = notificationData
            axios.post(`${config.url}/upload/notifConfig`, newNotificationData).then(data => {
                console.log(data)
                setRowData(data.data);
                setAdding(false)
                setOpenAddModal(false);
                // socket.emit("send_notification_config_data", data.data);
            });
        } catch (error) {
            console.error(error)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNotificationData({
            ...notificationData,
            [name]: value
        });
    };
    
    return (
    <div>
        {/* <Navbar></Navbar>
        <SideBar loc='Basic Education'></SideBar> */}
        <div className="p-4 sm:ml-64 mt-16">
            <div className='w-full'>
                <h1 className='text-xl font-semibold py-4'>Notifications</h1>
                <hr className='border-black' />
            </div>
            <div className='flex items-center justify-between'>
                <div className="relative w-72 py-5">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input onInput={(value) => {setSearchFilter(value.target.value)}} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                </div>
                <div>
                    <button onClick={()=> openAddModal ? setOpenAddModal(false) : setOpenAddModal(true)} data-modal-hide="default-modal" type="button" className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                        Add
                    </button>
                </div>
            </div>
            <div
            className="ag-theme-quartz h-[calc(100vh-18rem)]"
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    sideBar={"filters"}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={[100, 50, 20, 10]}
                    quickFilterText={searchFilter}
                    autoSizeStrategy={{type: 'fitGridWidth'}}
                    hideOVerlay={true}
                    overlayNoRowsTemplate={isDoneFetching ? '<div>No data found.</div>' : '<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]"><svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg><span className="sr-only">Loading...</span></div>'}
                    getRowId={getRowId}
                />
            </div>
        </div>

        <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openEditModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button type='button' className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenEditModal(false)}></button>
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <form onSubmit={updateNotificationData}>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Update Notification
                            </h3>
                            <button onClick={() => setOpenEditModal(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div>
                                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                                <input onChange={handleChange} value={notificationData.name} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                            <div>
                                <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                                <textarea onChange={handleChange} value={notificationData.message} id="message" name='message' rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                            </div>
                            <div>
                                <label for="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type</label>
                                <select onChange={handleChange} id="type" name="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option disabled selected={notificationData.type == null}></option>
                                    <option value="Credential Request Declined" selected={notificationData.type == "Credential Request Declined"}>Credential Request Declined</option>
                                    <option value="Credential Request Processing" selected={notificationData.type == "Credential Request Processing"}>Credential Request Processing</option>
                                    <option value="Credential Request Ready for Pickup" selected={notificationData.type == "Credential Request Ready for Pickup"}>Credential Request Ready For Pickup</option>
                                    <option value="Credential Request Completed" selected={notificationData.type == "Credential Request Completed"}>Credential Request Completed</option>
                                    <option value="Clearance Request Processing" selected={notificationData.type == "Clearance Request Processing"}>Clearance Request Processing</option>
                                    <option value="Documents Updated" selected={notificationData.type == "Documents Updated"}>Documents Updated</option>
                                </select>
                            </div>
                            <div>
                                <label for="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                                <select onChange={handleChange} id="status" name="status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="Active" selected={notificationData.status == "Active"}>Active</option>
                                    <option value="Inactive" selected={notificationData.status == "Inactive"}>Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <button onClick={() => setOpenEditModal(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                            <button data-modal-hide="default-modal" type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                
                                {
                                    updating ? <div role="status">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                        <span className="sr-only">Loading...</span>
                                    </div> : "Update"
                                }
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteData} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openAddModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button type='button' className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenAddModal(false)}></button>
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <form onSubmit={addNotificationData}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Add Notification Config
                            </h3>
                            <button onClick={() => setOpenAddModal(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div>
                                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                                <input onChange={handleChange} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                            <div>
                                <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                                <textarea onChange={handleChange} id="message" name='message' rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                            </div>
                            <div>
                                <label for="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type</label>
                                <select onChange={handleChange} id="type" name="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option disabled selected></option>
                                    <option value="Credential Request Declined">Credential Request Declined</option>
                                    <option value="Credential Request Processing">Credential Request Processing</option>
                                    <option value="Credential Request Ready for Pickup">Credential Request Ready for Pickup</option>
                                    <option value="Credential Request Completed">Credential Request Completed</option>
                                    <option value="Clearance Request Processing">Clearance Request Processing</option>
                                    <option value="Documents Updated">Documents Updated</option>
                                </select>
                            </div>
                            <div>
                                <label for="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                                <select onChange={handleChange} id="status" name="status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="Active" selected>Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <button onClick={() => setOpenAddModal(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                            <button data-modal-hide="default-modal" type="submit" className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                
                                {
                                    adding ? <div role="status">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                        <span className="sr-only">Loading...</span>
                                    </div> : "Add"
                                }
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
    )
}

export default Notifications