import React from "react";

export default (params) => (
    <span
        className="missionSpan w-full h-full flex items-center justify-center"
    >
        {
        <img
            alt={`${params.value}`}
            src={`https://www.ag-grid.com/example-assets/icons/${
            params.value ? "tick-in-circle" : "cross-in-circle"
            }.png`}
            className="missionIcon"
        />
        }
    </span>
)