import React from 'react'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from '../../components/Navbar';
import SideBar from '../../components/SideBar';
import booleanRenderer from "../../components/booleanRenderer.jsx";
import actionButtons from "../../components/actionButtons.jsx";
import axios from 'axios';
import { io } from 'socket.io-client';
import Papa from 'papaparse';
import config from '../../../config.js';

function BasicEducation(props) {
    const [rowData, setRowData] = React.useState([]);
    const [currentStudent, setCurrentStudent] = React.useState("");
    const [currentDocuments, setCurrentDocuments] = React.useState({});
    const [bulkDocuments, setBulkDocuments] = React.useState({});
    const [searchFilter, setSearchFilter] = React.useState("");
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [openBulkEditModal, setOpenBulkEditModal] = React.useState(false);
    const [openBulkDeleteModal, setOpenBulkDeleteModal] = React.useState(false);
    const [studentData, setStudentData] = React.useState({});
    const gridRef = React.useRef();
    const [updating, setUpdating] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [adding, setAdding] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [bulkStudentData, setBulkStudentData] = React.useState({});
    const [selectedStudentIds, setSelectedStudentIds] = React.useState([]);
    const [notifConfig, setNotifConfig] = React.useState([]);
    const [notificationsData, setNotificationsData] = React.useState([]);
    const [isDoneFetching, setIsDoneFetching] = React.useState(false);
    const [tabs, setTabs] = React.useState("Active");
    const [colDefs, setColDefs] = React.useState([
        { 
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 40
        },
        { field: "studentNumber", headerName: "Student No.", maxWidth: 120 },
        { field: "fullName", headerName: "Full Name"},
        { field: "program", headerName: "Program" },
        { field: "school", headerName: "Name of School" },
        { field: "address", headerName: "Address" },
        { field: "documentList", headerName: "F137", maxWidth: 80, 
            cellRenderer: props => {
                const doc = props.value;
                if (doc != null && doc["f137"] != null) {
                    return booleanRenderer({value: doc["f137"]["isSubmitted"]});
                } else {
                    return booleanRenderer({value: false});
                }
            }
        },
        { field: "documentList", headerName: "F138", maxWidth: 80, 
            cellRenderer: props => {
                const doc = props.value;
                if (doc != null && doc["f138"] != null) {
                    return booleanRenderer({value: doc["f138"]["isSubmitted"]});
                } else {
                    return booleanRenderer({value: false});
                }
            }
        },
        { field: "documentList", headerName: "PSA BC", maxWidth: 80, 
            cellRenderer: props => {
                const doc = props.value;
                if (doc != null && doc["psabc"] != null) {
                    return booleanRenderer({value: doc["psabc"]["isSubmitted"]});
                } else {
                    return booleanRenderer({value: false});
                }
            }
        },
        { field: "documentList", headerName: "HSD", maxWidth: 80, 
            cellRenderer: props => {
                const doc = props.value;
                if (doc != null && doc["hsd"] != null) {
                    return booleanRenderer({value: doc["hsd"]["isSubmitted"]});
                } else {
                    return booleanRenderer({value: false});
                }
            }
        },
        { field: "documentList", headerName: "GMC", maxWidth: 80, 
            cellRenderer: props => {
                const doc = props.value;
                if (doc != null && doc["gmc"] != null) {
                    return booleanRenderer({value: doc["gmc"]["isSubmitted"]});
                } else {
                    return booleanRenderer({value: false});
                }
            }
        },
        { field: "documentList", headerName: "Voucher", maxWidth: 80, 
            cellRenderer: props => {
                const doc = props.value;
                if (doc != null && doc["voucher"] != null) {
                    return booleanRenderer({value: doc["voucher"]["isSubmitted"]});
                } else {
                    return booleanRenderer({value: false});
                }
            }
        },
        { field: "id", headerName: "Actions", 
            cellRenderer: props => {
                return (
                    <div className="flex items-center gap-1 w-full h-full">
                        <button type="button" onClick={() => {setCurrentStudent(props.value); setOpenEditModal(true); console.log("id: " + props.value)}} className="w-8 h-8 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" fill-rule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"></path> </g></svg>
                        </button>
                        {/* <button type="button" onClick={() => {setCurrentStudent(props.value); setOpenDeleteModal(true)}} className="w-8 h-8 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </button> */}
                    </div>
                )
            }
        , },
    ]);

    // const socket = io.connect(config.url);

    React.useEffect(() => {
        axios({
            url: `${config.url}/students`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            rawData.forEach(element => {
                element.action = element.userID;
                element.fullName = `${element.firstName} ${element.lastName}`;
            });
            setRowData(rawData.filter(student => student["type"] != "transferee" && student["type"] != "freshmen"))
            setIsDoneFetching(true);
        })

        axios({
            url: `${config.url}/notifConfig`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotifConfig(rawData)
        })

        axios({
            url: `${config.url}/notifications`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotificationsData(rawData)
        })
    }, [])

    // React.useEffect(() => {
    //     socket.on("student_data_change", (data) => {
    //         setRowData(data);
    //     })
    // }, [socket]);


    React.useEffect(() => {
        const fetchStudentData = () => {
            const newStudentData = rowData.filter(el => {
                return el.id == currentStudent;
            })
            setStudentData(newStudentData[0] != null ? newStudentData[0] : {});
            console.log(newStudentData[0] != null ?newStudentData[0]:{})
            setCurrentDocuments(newStudentData[0] != null ? (newStudentData[0]["documentList"]??{}):{})
        }
        fetchStudentData();
    }, [currentStudent]);

    

    const getRowId = React.useCallback((params) => {
        const id = params.data.id;
        return id;
    }, []);

    const updateStudentData = (e) => {
        e.preventDefault();
        try {
            setUpdating(true)
            console.log(studentData)
            let newStudentData = {
                ...studentData,
                fullName: studentData.firstName + " " + studentData.lastName,
                sessionID: Math.floor(Math.random()*100000),
                documentList: currentDocuments
            }
            axios.post(`${config.url}/update/students/`+studentData.id, newStudentData).then(() => {
                const index = rowData.findIndex(index => {
                    return index.userID == newStudentData.userID;
                });
                rowData[index] = newStudentData;
                console.log(rowData);
                setRowData(rowData);
                // socket.emit("send_student_data", rowData);
                updateData(studentData.id, newStudentData);
                setUpdating(false)
                setOpenEditModal(false);
            });
            console.log(notifConfig)
            if (notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Documents Updated").length != 0) {
                notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Documents Updated").forEach(data => {
                    axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: studentData.id, isSeen: false}).then(() => {
                        setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: studentData.id, isSeen: false}]);
                        // socket.emit("send_notification_data", notificationsData);
                        setUpdating(false)
                        setOpenEditModal(false);
                    });
                    axios.get(`${config.url}/students`).then(el => {
                        const student = el.data.filter(e => e.id == studentData);
                        student.forEach(e => {
                            if (e.fCMToken != null) {
                                axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                            }
                        })
                    })
                    axios.get(`${config.url}/students`).then(el => {
                        const student = el.data.filter(e => e.id == studentData.id);
                        student.forEach(e => {
                            if (e.fCMToken != null) {
                                axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                            }
                        })
                    })
                });
            }
        } catch (error) {
            console.error(error)
        }
    }

    const deleteStudentData = (e) => {
        e.preventDefault();
        setDeleting(true);
        const selectedIDs = [{studentID: studentData.id, userID: studentData.studentID}];
        axios.post(`${config.url}/delete-students/`, selectedIDs )
        .then(() => {
            const remainingData = rowData.filter(row => !selectedIDs.some(e => e.studentID == row.id));
            setRowData(remainingData);
            // socket.emit("send_student_data", remainingData);
            setDeleting(false);
            setOpenDeleteModal(false);
        })
        .catch((error) => {
            console.error('Error deleting students:', error);
        });
    }

    const handleChange = (e) => {
        const { name, value, type, checked, id } = e.target;
        if (type == "checkbox") {
            setStudentData({
                ...studentData,
                [name]: checked
            });
            const dateNow = Date(Date.now()).split(" ");
            setCurrentDocuments({
                ...currentDocuments,
                [name]: {
                    isSubmitted: checked,
                    receivedBy: checked ? props.currentUser["name"] : "",
                    date: checked ? `${dateNow[1]} ${dateNow[2]}, ${dateNow[3]}`: "",
                }
            })
        } else if (id == "remarks") {
            setCurrentDocuments({...currentDocuments, [name]: {...currentDocuments[name], remarks: value}});
        } else {
            setStudentData({
                ...studentData,
                [name]: value
            });
        }
    };

    const updateData = React.useCallback((id, data) => {
        console.log(data)
        const rowNode = gridRef.current.api.getRowNode(id);
        rowNode.updateData(data);
    }, []);


    const handleFileUpload = (e) => {
        setAdding(true);
        const file = e.target.files[0];
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                const formattedData = results.data.map((row, index) => {
                    const camelCaseRow = {};
                    for (const key in row) {
                        camelCaseRow[camelize(key)] = row[key];
                    }
                    return {
                        ...camelCaseRow,
                        fullName: `${camelCaseRow.firstName} ${camelCaseRow.lastName}`,
                        type: camelCaseRow.type.toLowerCase()
                    };
                });
    
                const response = await axios.get(`${config.url}/students`);
                const existingData = response.data;
    
                const newStudents = formattedData.filter(student => 
                    !existingData.some(existing => existing.email === student.email)
                );

                if (newStudents.length > 0) {
                    axios.post(`${config.url}/upload-students/`, newStudents).then(id => {
                        console.log(id.data);
                        var studentIds = newStudents.map((data, index) => {
                            return {...data, id: id.data[index].id}
                        })
                        setRowData([...rowData, ...studentIds.filter(student => student["type"] != "transferee" && student["type"] != "freshmen")]);
                        console.table([...rowData, ...studentIds.filter(student => student["type"] != "transferee" && student["type"] != "freshmen")])
                        // socket.emit("send_student_data", [...rowData, ...studentIds.filter(student => student["type"] != "transferee" && student["type"] != "freshmen")]);
                        setAdding(false);
                    });
                }
            },
            error: (error) => {
                console.error("Error parsing CSV file:", error);
            }
        });
    };

    function camelize(str) {
        if (/^[A-Z]+$/.test(str)) {
            return str.toLowerCase();
        }
    
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    const handleBulkChange = (e) => {
        const { name, value, type, checked, id } = e.target;
        if (type == "checkbox") {
            setBulkStudentData({
                ...bulkStudentData,
                [name]: checked
            });
            const dateNow = Date(Date.now()).split(" ");
            setBulkDocuments({
                ...bulkDocuments,
                [name]: {
                    isSubmitted: checked,
                    receivedBy: checked ? props.currentUser["name"] : "",
                    date: checked ? `${dateNow[1]} ${dateNow[2]}, ${dateNow[3]}`: "",
                }
            })
        } else if (id == "remarks") {
            setBulkDocuments({...bulkDocuments, [name]: {...bulkDocuments[name], remarks: value}});
        } else {
            setBulkStudentData({
                ...bulkStudentData,
                [name]: value
            });
        }
    };

    const updateBulkStudentData = (e) => {
        e.preventDefault();
        setUpdating(true);
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => ({
            ...node.data,
            ...bulkStudentData,
            documentList: bulkDocuments
        }));
        console.log(bulkDocuments)
        console.table(bulkStudentData)
        axios.post(`${config.url}/update-students/`, selectedData)
        .then(() => {
            const updatedRowData = rowData.map(row => {
                const updatedStudent = selectedData.find(student => student.id === row.id);
                return updatedStudent ? updatedStudent : row;
            });
            setRowData(updatedRowData);
            // socket.emit("send_student_data", updatedRowData);
            setOpenBulkEditModal(false);
            setUpdating(false);
            setBulkDocuments({});
        })
        .catch((error) => {
            console.error('Error updating students:', error);
            setUpdating(false);
        });
        notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Documents Updated").forEach(data => {
            selectedData.forEach(student => {
                axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: student.id, isSeen: false}).then(() => {
                    setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: student.id, isSeen: false}]);
                    // socket.emit("send_notification_data", notificationsData);
                });
                axios.get(`${config.url}/students`).then(el => {
                    const newStudent = el.data.filter(e => e.id == student.id);
                    newStudent.forEach(e => {
                        if (e.fCMToken != null) {
                            axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                        }
                    })
                })
            })
        });
    };

    const deleteStudents = () => {
        setDeleting(true);
        const selectedIDs = selectedRows.map(data => {
            return {studentID: data.id, userID: data.studentID};
        })
        axios.post(`${config.url}/delete-students/`, selectedIDs )
            .then(() => {
                const remainingData = rowData.filter(row => !selectedIDs.some(e => e.studentID == row.id));
                setRowData(remainingData);
                // socket.emit("send_student_data", remainingData);
                setSelectedStudentIds([]);
                setDeleting(false);
                setOpenBulkDeleteModal(false);
            })
            .catch((error) => {
                console.error('Error deleting students:', error);
            });
    };
    
    return (
    <div>
        {/* <Navbar></Navbar>
        <SideBar loc='Basic Education'></SideBar> */}
        <div className="p-4 sm:ml-64 mt-16">
            <div className='w-full'>
                <h1 className='text-xl font-semibold py-4'>Basic Education</h1>
                <hr className='border-black' />
            </div>
            <div className='flex items-center justify-between'>
                <div className="relative w-72 py-5">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input onInput={(value) => {setSearchFilter(value.target.value)}} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                </div>
                <div className={`${selectedRows.length > 0 ? 'flex': 'hidden'} gap-1`}>
                    <button onClick={() => setOpenBulkEditModal(true)} className="mr-2 p-2 w-20 bg-blue-600 text-white rounded">Edit</button>
                    {/* <button onClick={() => setOpenBulkDeleteModal(true)} className="p-2 w-20 bg-red-600 text-white rounded">Delete</button> */}
                </div>
                <div className='relative'>
                    <button data-modal-hide="default-modal" type="button" className="text-white relative bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                        <input type="file" accept=".csv" onChange={handleFileUpload} className="opacity-0 w-full h-full absolute top-0 left-0 z-50"/>
                        {
                                adding ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "+ Add Student"
                            }
                    </button>
                </div>
            </div>

            <div className='max-w-64'>
                <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                    <li class="me-2">
                        <button onClick={() => setTabs("Active")} className={tabs == "Active" ? `inline-block p-4 text-green-600 bg-green-100 rounded-t-lg` : `inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50`}>Active</button>
                    </li>
                    <li class="me-2">
                        <button onClick={() => setTabs("Inactive")} className={tabs == "Inactive" ? `inline-block p-4 text-green-600 bg-green-100 rounded-t-lg` : `inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50`}>Inactive</button>
                    </li>
                </ul>
            </div>

            <div
            className="ag-theme-quartz h-[calc(100vh-18rem)]">
                <AgGridReact
                    ref={gridRef}
                    rowData={tabs == "Active" ? rowData.filter(data => data['status'] == null || data['status'] == "Active") : rowData.filter(data => data['status'] == "Inactive")}
                    columnDefs={colDefs}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={[100, 50, 20, 10]}
                    quickFilterText={searchFilter}
                    autoSizeStrategy={{type: 'fitCellContents'}}
                    hideOVerlay={true}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    onSelectionChanged={() => {setSelectedRows(gridRef.current.api.getSelectedRows())}}
                    overlayNoRowsTemplate={isDoneFetching ? '<div>No data found.</div>' : '<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]"><svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg><span className="sr-only">Loading...</span></div>'}
                    getRowId={getRowId}
                />
            </div>
        </div>

        <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openEditModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button type='button' className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenEditModal(false)}></button>
            <div className="relative p-4 w-full max-w-5xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <form onSubmit={updateStudentData}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Student Details
                            </h3>
                            <button onClick={() => setOpenEditModal(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div className='flex border border-black'>
                                <div className='flex-1'>
                                    <div className='bg-yellow-300 border-b border-black p-2'>
                                        <h1 className='text-xs font-semibold'>LAST NAME</h1>
                                    </div>
                                    <div className=' w-full h-auto'>
                                        <input name="lastName" onChange={handleChange} type='text' className='all-unset text-lg w-full h-full p-2 border-none' value={studentData.lastName} />
                                    </div>
                                </div>
                                <div className='flex-1 border-l border-black'>
                                    <div className='bg-yellow-300 border-b border-black p-2'>
                                        <h1 className='text-xs font-semibold'>FIRST NAME</h1>
                                    </div>
                                    <div className=' w-full h-auto'>
                                        <input name="firstName" onChange={handleChange} type='text' className='all-unset text-lg w-full h-full p-2 border-none' value={studentData.firstName} />
                                    </div>
                                </div>
                                <div className='flex-1 border-l border-black'>
                                    <div className='bg-yellow-300 border-b border-black p-2'>
                                        <h1 className='text-xs font-semibold'>MIDDLE NAME</h1>
                                    </div>
                                    <div className=' w-full h-auto'>
                                        <input name="middleName" onChange={handleChange} type='text' className='all-unset text-lg w-full h-full p-2 border-none' value={studentData.middleName} />
                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-between items-center'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex items-center border border-black'>
                                        <div className='bg-yellow-300 border-r border-black p-2 w-28'>
                                            <h1 className='text-sm font-semibold text-center'>STUDENT NO:</h1>
                                        </div>
                                        <input name="studentNumber" onChange={handleChange} type='text' className='text-sm font-semibold p-2 border-none' value={studentData.studentNumber} />
                                    </div>
                                    <div className='flex items-center border border-black'>
                                        <div className='bg-yellow-300 border-r border-black p-2 w-28'>
                                            <h1 className='text-sm font-semibold text-center'>LRN:</h1>
                                        </div>
                                        <input name="lrn" onChange={handleChange} type='text' className='text-sm font-semibold p-2 border-none' value={studentData.lrn} />
                                    </div>
                                </div>

                                <div className='grid grid-cols-2 grid-rows-2 gap-2'>
                                    <div className='flex-1 border border-black w-36'>
                                        <div className='bg-yellow-300 border-b border-black p-[0.2rem]'>
                                            <h1 className='text-[0.70rem] font-bold'>Strand/Track</h1>
                                        </div>
                                        <input name="strand" onChange={handleChange} type='text' className='text-xs p-[0.2rem] border-none w-full h-auto' value={studentData.strand} />
                                    </div>
                                    <div className='flex-1 border border-black w-36'>
                                        <div className='bg-yellow-300 border-b border-black p-[0.2rem]'>
                                            <h1 className='text-[0.70rem] font-bold'>School Year</h1>
                                        </div>
                                        <input name="schoolYear" onChange={handleChange} type='text' className='text-xs p-[0.2rem] border-none w-full h-auto' value={studentData.schoolYear} />
                                    </div>
                                    <div className='flex-1 border border-black w-36'>
                                        <div className='bg-yellow-300 border-b border-black p-[0.2rem]'>
                                            <h1 className='text-[0.70rem] font-bold'>Section</h1>
                                        </div>
                                        <input name="section" onChange={handleChange} type='text' className='text-xs p-[0.2rem] border-none w-full h-auto' value={studentData.section} />
                                    </div>
                                    <div className='flex-1 border border-black w-36'>
                                        <div className='bg-yellow-300 border-b border-black p-[0.2rem]'>
                                            <h1 className='text-[0.70rem] font-bold'>Grade Level</h1>
                                        </div>
                                        <input name="gradeLevel" onChange={handleChange} type='text' className='text-xs p-[0.2rem] border-none w-full h-auto' value={studentData.gradeLevel} />
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-5 gap-1'>
                                <div className='grid grid-cols-6 col-span-2 border border-black flex-1'>
                                    <div className='bg-yellow-300 col-span-6 p-2 border-b border-black'>For New Student/Transferee:</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="psabc" onChange={handleChange} checked={currentDocuments["psabc"] != null && currentDocuments["psabc"]["isSubmitted"]} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>PSA Birth Certificate</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="f137" onChange={handleChange} checked={currentDocuments["f137"] != null && currentDocuments["f137"]["isSubmitted"]} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />                                    
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>F137/SF10</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="f138" onChange={handleChange} checked={currentDocuments["f138"] != null && currentDocuments["f138"]["isSubmitted"]} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>F138</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="hsd" onChange={handleChange} checked={currentDocuments["hsd"] != null && currentDocuments["hsd"]["isSubmitted"]} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>High School Diploma</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="gmc" onChange={handleChange} checked={currentDocuments["gmc"] != null && currentDocuments["gmc"]["isSubmitted"]} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>Good Moral Character</div>
                                    <div className='p-2 border-r border-black flex items-center justify-center'>
                                        <input name="voucher" onChange={handleChange} checked={currentDocuments["voucher"] != null && currentDocuments["voucher"]["isSubmitted"]} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2'>ESC/QVR/SHS Voucher</div>
                                </div>

                                <div className='grid grid-rows-7 border border-black'>
                                    <div className='bg-yellow-300 p-2 border-b border-black'>Date Submitted</div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["psabc"] != null ? currentDocuments["psabc"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["f137"] != null ? currentDocuments["f137"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["f138"] != null ? currentDocuments["f138"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["hsd"] != null ? currentDocuments["hsd"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["gmc"] != null ? currentDocuments["gmc"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2'>
                                        <h1>{currentDocuments["voucher"] != null ? currentDocuments["voucher"]["date"]:""}</h1>
                                    </div>
                                </div>

                                <div className='grid grid-rows-7 border border-black'>
                                    <div className='bg-yellow-300 p-2 border-b border-black'>Received By</div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["psabc"] != null ? currentDocuments["psabc"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["f137"] != null ? currentDocuments["f137"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["f138"] != null ? currentDocuments["f138"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["hsd"] != null ? currentDocuments["hsd"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{currentDocuments["gmc"] != null ? currentDocuments["gmc"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2'>
                                        <h1>{currentDocuments["voucher"] != null ? currentDocuments["voucher"]["receivedBy"]:""}</h1>
                                    </div>
                                </div>

                                <div className='grid grid-rows-7 border border-black'>
                                    <div className='bg-yellow-300 p-2 border-b border-black'>Remarks</div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleChange} name="psabc" className='w-full border-none' disabled={currentDocuments["psabc"] == null} value={currentDocuments["psabc"] != null ? currentDocuments["psabc"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleChange} name="f137" className='w-full border-none' disabled={currentDocuments["f137"] == null} value={currentDocuments["f137"] != null ? currentDocuments["f137"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleChange} name="f138" className='w-full border-none' disabled={currentDocuments["f138"] == null} value={currentDocuments["f138"] != null ? currentDocuments["f138"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleChange} name="hsd" className='w-full border-none' disabled={currentDocuments["hsd"] == null} value={currentDocuments["hsd"] != null ? currentDocuments["hsd"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleChange} name="gmc" className='w-full border-none' disabled={currentDocuments["gmc"] == null} value={currentDocuments["gmc"] != null ? currentDocuments["gmc"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=''>
                                        <input type="text" onChange={handleChange} name="voucher" className='w-full border-none' disabled={currentDocuments["voucher"] == null} value={currentDocuments["voucher"] != null ? currentDocuments["voucher"]["remarks"]:""} id="remarks" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <div>
                                <select name="status" required onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="Active" selected={studentData['status'] == null || studentData["status"] == "Active"}>Active</option>
                                    <option value="Inactive" selected={studentData["status"] == "Inactive"}>Inactive</option>
                                </select>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button onClick={() => setOpenEditModal(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                                <button data-modal-hide="default-modal" type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    
                                    {
                                        updating ? <div role="status">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Update"
                                    }
                                    
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openBulkEditModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button type='button' className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenBulkEditModal(false)}></button>
            <div className="relative p-4 w-full max-w-5xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <form onSubmit={updateBulkStudentData}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Student Details
                            </h3>
                            <button onClick={() => setOpenBulkEditModal(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div className='grid grid-cols-5 gap-1'>
                                <div className='grid grid-cols-6 col-span-2 border border-black flex-1'>
                                    <div className='bg-yellow-300 col-span-6 p-2 border-b border-black'>For New Student/Transferee:</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="psabc" onChange={handleBulkChange} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>PSA Birth Certificate</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="f137" onChange={handleBulkChange} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>F137/SF10</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="f138" onChange={handleBulkChange} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>F138</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="hsd" onChange={handleBulkChange} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>High School Diploma</div>
                                    <div className='p-2 border-b border-r border-black flex items-center justify-center'>
                                        <input name="gmc" onChange={handleBulkChange} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2 border-b border-black flex'>Good Moral Character</div>
                                    <div className='p-2 border-r border-black flex items-center justify-center'>
                                        <input name="voucher" onChange={handleBulkChange} id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className='col-span-5 p-2'>ESC/QVR/SHS Voucher</div>
                                </div>

                                <div className='grid grid-rows-7 border border-black'>
                                    <div className='bg-yellow-300 p-2 border-b border-black'>Date Submitted</div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["psabc"] != null ? bulkDocuments["psabc"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["f137"] != null ? bulkDocuments["f137"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["f138"] != null ? bulkDocuments["f138"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["hsd"] != null ? bulkDocuments["hsd"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["gmc"] != null ? bulkDocuments["gmc"]["date"]:""}</h1>
                                    </div>
                                    <div className='p-2'>
                                        <h1>{bulkDocuments["voucher"] != null ? bulkDocuments["voucher"]["date"]:""}</h1>
                                    </div>
                                </div>

                                <div className='grid grid-rows-7 border border-black'>
                                    <div className='bg-yellow-300 p-2 border-b border-black'>Received By</div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["psabc"] != null ? bulkDocuments["psabc"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["f137"] != null ? bulkDocuments["f137"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["f138"] != null ? bulkDocuments["f138"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["hsd"] != null ? bulkDocuments["hsd"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2 border-b border-black'>
                                        <h1>{bulkDocuments["gmc"] != null ? bulkDocuments["gmc"]["receivedBy"]:""}</h1>
                                    </div>
                                    <div className='p-2'>
                                        <h1>{bulkDocuments["voucher"] != null ? bulkDocuments["voucher"]["receivedBy"]:""}</h1>
                                    </div>
                                </div>

                                <div className='grid grid-rows-7 border border-black'>
                                    <div className='bg-yellow-300 p-2 border-b border-black'>Remarks</div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleBulkChange} name="psabc" className='w-full border-none' disabled={bulkDocuments["psabc"] == null} value={bulkDocuments["psabc"] != null ? bulkDocuments["psabc"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleBulkChange} name="f137" className='w-full border-none' disabled={bulkDocuments["f137"] == null} value={bulkDocuments["f137"] != null ? bulkDocuments["f137"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleBulkChange} name="f138" className='w-full border-none' disabled={bulkDocuments["f138"] == null} value={bulkDocuments["f138"] != null ? bulkDocuments["f138"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleBulkChange} name="hsd" className='w-full border-none' disabled={bulkDocuments["hsd"] == null} value={bulkDocuments["hsd"] != null ? bulkDocuments["hsd"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=' border-b border-black'>
                                        <input type="text" onChange={handleBulkChange} name="gmc" className='w-full border-none' disabled={bulkDocuments["gmc"] == null} value={bulkDocuments["gmc"] != null ? bulkDocuments["gmc"]["remarks"]:""} id="remarks" />
                                    </div>
                                    <div className=''>
                                        <input type="text" onChange={handleBulkChange} name="voucher" className='w-full border-none' disabled={bulkDocuments["voucher"] == null} value={bulkDocuments["voucher"] != null ? bulkDocuments["voucher"]["remarks"]:""} id="remarks" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <div>
                                <select name="status" required onChange={handleBulkChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="Active" selected={studentData['status'] == null || studentData["status"] == "Active"}>Active</option>
                                    <option value="Inactive" selected={studentData["status"] == "Inactive"}>Inactive</option>
                                </select>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button onClick={() => setOpenBulkEditModal(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                                <button data-modal-hide="default-modal" type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    
                                    {
                                        updating ? <div role="status">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Update"
                                    }
                                    
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteStudentData} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openBulkDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenBulkDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenBulkDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteStudents} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenBulkDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    )
}

export default BasicEducation