import React, { createContext, useState } from 'react';
import axios from 'axios';
import { auth } from '../firebase';
import config from '../config';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({ token: sessionStorage.getItem('token'), user: null });

    const login = async (formData) => {
        const res = await axios.post(`${config.url}/login`, formData)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
        if (res) {
            axios({
                url: `${config.url}/accounts`,
                method: "GET"
            })
            .then(data => {
                const rawData = data.data;
                let fetchedUser = rawData.filter(user => user.userID == res);
                if (fetchedUser.length > 0) {
                    sessionStorage.setItem('token', res);
                    setAuthState({ token: res, user: null });
                    window.location = "/";
                } else {
                    window.location = "?status=error";
                }
            })
        } else {
            window.location = "?status=error";
        }
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        setAuthState({ token: null, user: null });
    };

    return (
        <AuthContext.Provider value={{ authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
