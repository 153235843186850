import React from 'react'
import Navbar from '../components/Navbar'
import SideBar from './../components/SideBar';
import DocChart from '../components/DocChart';
import axios from 'axios';
import { io } from 'socket.io-client';
import config from '../../config';
import { AgGridReact } from 'ag-grid-react';

function Dashboard() {
    const [credentials, setCredentials] = React.useState([]);
    const [clearance, setClearance] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [tableTitle, setTableTitle] = React.useState([]);
    const [openTable, setOpenTable] = React.useState(false);
    const [colDefs, setColDefs] = React.useState([]);

    // const socket = io.connect(config.url);

    React.useEffect(() => {
        axios({
            url: `${config.url}/credentials`,
            method: "GET"
        })
        .then(data => {
            setCredentials(data.data)
        })

        axios({
            url: `${config.url}/clearance`,
            method: "GET"
        })
        .then(data => {
            setClearance(data.data)
        })
    }, [])

    // React.useEffect(() => {
    //     socket.on("credentials_data_change", (data) => {
    //         setCredentials(data);
    //     })
    //     socket.on("clearance_data_change", (data) => {
    //         setClearance(data);
    //     })
    // }, [socket])

    React.useEffect(() => {
        setColDefs([
            { field: "fullName", headerName: "Full Name", flex: 1},
            { field: "status", headerName: "Status", flex: 1},
            { field: "date", headerName: "Date", flex: 1, 
                cellRenderer: props => {
                    if (props.value != null) {
                        let date = new Date(props.value.seconds*1000);
                        date = date.toString().split(" ")
                        return <h1> {`${date[1]} ${date[2]}, ${date[3]}`}</h1>
                    } else {
                        return <h1></h1>
                    }
                }
            },
        ])
    }, [tableData])

    const setTable = (table) => {
        switch(table) {
            case "Pending Documents":
                setTableData(credentials.filter(data => data["status"] != "Completed"));
                break;
            case "Completed Documents":
                setTableData(credentials.filter(data => data["status"] == "Completed" || data["status"] == "Confirmed"));
                break;
            case "Numbers of Credentials Requests":
                setTableData(credentials);
                break;
            case "Pending Clearance":
                setTableData(clearance.filter(data => data["status"] == "Pending" || data["status"] == null || data["status"] == "Processing"));
                break;
            case "Completed Clearance":
                setTableData(clearance.filter(data => data["status"] == "Completed"));
                break;
            case "Numbers of Clearance Requests":
                setTableData(clearance);
                break;
        }
        setTableTitle(table);
    }
    
    
    return (
        <div>
            {/* <Navbar></Navbar>
            <SideBar loc='Dashboard'></SideBar> */}
            <div class="p-4 sm:ml-64 mt-16">
                <div className='w-full'>
                    <h1 className='text-xl font-semibold py-4'>Dashboard</h1>
                    <hr className='border-black' />
                </div>
                <div class="mt-5">
                    <div class="grid grid-cols-3 gap-4 mb-4">
                        <button onClick={() => {setTable("Pending Documents"); setOpenTable(true)}} class="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-orange-400 dark:bg-gray-800 text-white">
                            <svg class="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Pending Documents</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{credentials.filter(data => data["status"] != "Completed").length}</h1>
                            </div>
                        </button>
                        <button onClick={() => {setTable("Completed Documents"); setOpenTable(true)}} class="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-green-400 dark:bg-gray-800 text-white">
                            <svg class="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Completed Documents</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{credentials.filter(data => data["status"] == "Completed" || data["status"] == "Confirmed").length}</h1>
                            </div>
                        </button>
                        <button onClick={() => {setTable("Numbers of Credentials Requests"); setOpenTable(true)}} class="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-blue-400 dark:bg-gray-800 text-white">
                            <svg class="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Numbers of<br></br> Credentials Requests</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{credentials.length}</h1>
                            </div>
                        </button>
                    </div>
                    <div class="grid grid-cols-3 gap-4 mb-4">
                        <button onClick={() => {setTable("Pending Clearance"); setOpenTable(true)}} class="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-orange-400 dark:bg-gray-800 text-white">
                            <svg class="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Pending Clearance</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{clearance.filter(data => data["status"] == "Pending" || data["status"] == null || data["status"] == "Processing").length}</h1>
                            </div>
                        </button>
                        <button onClick={() => {setTable("Completed Clearance"); setOpenTable(true)}} class="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-green-400 dark:bg-gray-800 text-white">
                            <svg class="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Completed Clearance</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{clearance.filter(data => data["status"] == "Completed").length}</h1>
                            </div>
                        </button>
                        <button onClick={() => {setTable("Numbers of Clearance Requests"); setOpenTable(true)}} class="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-blue-400 dark:bg-gray-800 text-white">
                            <svg class="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Numbers of<br></br> Clearance Requests</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{clearance.length}</h1>
                            </div>
                        </button>
                    </div>
                    <DocChart />
                </div>
            </div>
            <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openTable ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
                <button type='button' className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenTable(false)}></button>
                <div className="relative p-4 w-full max-w-5xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {tableTitle}
                            </h3>
                            <button onClick={() => setOpenTable(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div
                            className="ag-theme-quartz h-[calc(100vh-20rem)]"
                            >
                                <AgGridReact
                                    rowData={tableData}
                                    columnDefs={colDefs}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationPageSizeSelector={[100, 50, 20, 10]}
                                    hideOVerlay={true}
                                    suppressRowClickSelection={true}
                                    overlayNoRowsTemplate='<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]">
                                                                <svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                                                <span className="sr-only">Loading...</span>
                                                            </div>'
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <button onClick={() => setOpenTable(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard