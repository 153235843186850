import React from 'react'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import booleanRenderer from "../components/booleanRenderer.jsx";
import actionButtons from "../components/actionButtons.jsx";
import axios from 'axios';
import { io } from 'socket.io-client';
import { Reorder } from 'framer-motion';
import config from '../../config.js';

function ApproverClearance(props) {
    const [rowData, setRowData] = React.useState([]);
    const [searchFilter, setSearchFilter] = React.useState("");
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const gridRef = React.useRef();
    const [updating, setUpdating] = React.useState(false);
    const [colDefs, setColDefs] = React.useState();
    const [items, setItems] = React.useState([12345]);
    const [clearance, setClearance] = React.useState([{id: 12345}]);
    const [currentClearance, setCurrentClearance] = React.useState("");
    const [clearanceData, setClearanceData] = React.useState({});
    const [initialClearance, setInitialClearance] = React.useState([]);
    const [notifConfig, setNotifConfig] = React.useState([]);
    const [notificationsData, setNotificationsData] = React.useState([]);
    const [isApproved, setIsApproved] = React.useState(true);
    const [note, setNote] = React.useState("");
    const [selectedRow, setSelectedRow] = React.useState({});
    const [isDoneFetching, setIsDoneFetching] = React.useState(false);
    const [otherReason, setOtherReason] = React.useState(false);
    // const socket = io.connect(config.url);

    React.useEffect(() => {
        axios({
            url: `${config.url}/clearance`,
            method: "GET"
        })
        .then(data => {
            setInitialClearance(data.data);
            let rawData = data.data.filter(e => e["clearanceList"] && e["clearanceList"].some(data => data["department"] == props.currentUser["department"] && (data["approver"] == props.currentUser["name"] || data["approver"] == null || !data["approver"]) && data["status"] != "Approved"))
            .map(el => {
                const index = el["clearanceList"].map(e => e.department).indexOf(props.currentUser["department"]);
                
                let newClearanceList = [];
                el["clearanceList"].forEach((e, i) => {
                    if (index >= i) {
                        newClearanceList.push(e);
                    }
                });
                if ((newClearanceList.length == 1 && index == 0) || (index != 0 && newClearanceList[index-1]["status"] == "Approved")) {
                    return {...el, clearanceList: newClearanceList};
                }
                return null;
            })
            .filter(data => data != null);
            setRowData(rawData)
            setIsDoneFetching(true)
        })

        axios({
            url: `${config.url}/notifConfig`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotifConfig(rawData)
        })

        axios({
            url: `${config.url}/notifications`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotificationsData(rawData)
        })
    }, [])

    React.useEffect(() => {
        setColDefs([
            // { field: "studentNumber", headerName: "Student No.", maxWidth: 120 },
            { field: "fullName", headerName: "Full Name", flex: 1},
            // { field: "purpose", headerName: "Purpose", flex: 1},
            // { field: "status", headerName: "Status", cellStyle: {"text-transform": "capitalize"}, flex: 1,},
            { field: "date", headerName: "Date", flex: 1, 
                cellRenderer: props => {
                    if (props.value != null) {
                        let date = new Date(props.value.seconds*1000);
                        date = date.toString().split(" ")
                        return <h1> {`${date[1]} ${date[2]}, ${date[3]}`}</h1>
                    } else {
                        return <h1></h1>
                    }
                },
            },
        ])
        
    }, [rowData])

    // React.useEffect(() => {
    //     socket.on("clearance_data_change", (data) => {
    //         // console.log(data)
    //         setInitialClearance(data);
    //         let rawData = data.filter(e => e["clearanceList"] && e["clearanceList"].some(data => data["department"] == props.currentUser["department"] && (data["approver"] == props.currentUser["name"] || data["approver"] == null || !data["approver"]) && data["status"] != "Approved")).map(el => {
    //             const index = el["clearanceList"].map(e => e.department).indexOf(props.currentUser["department"]);
                
    //             let newClearanceList = [];
    //             el["clearanceList"].forEach((e, i) => {
    //                 if (index >= i) {
    //                     newClearanceList.push(e);
    //                 }
    //             });
    //             if ((newClearanceList.length == 1 && index == 0) || (index != 0 && newClearanceList[index-1]["status"] == "Approved")) {
    //                 return {...el, clearanceList: newClearanceList};
    //             }
    //             return null;
    //         }).filter(data => data != null);
    //         setRowData(rawData)
    //     })
    //     socket.on("clearance_list_change", (data) => {
    //         setClearance(data);
    //     })
    //     socket.on("clearance_items_change", (data) => {
    //         setItems(data);
    //     })
    // }, [socket]);

    // React.useEffect(() => {
    //     const fetchClearanceData = () => {
    //         const clearanceData = rowData.filter(el => {
    //             return el.id == currentClearance;
    //         })
    //         if (clearanceData[0] != null) {
    //             const newClearanceData = clearanceData[0];
    //             const index = newClearanceData["clearanceList"].map(e => e.department).indexOf(props.currentUser["department"]);
    //             console.log(index)
    //             newClearanceData["clearanceList"] = newClearanceData["clearanceList"].map((data, i) => {
    //                 if (i < index) {
    //                     return data;
    //                 }
    //             });
    //             console.log(newClearanceData["clearanceList"]);
    //             setClearanceData(newClearanceData);
    //         }
    //     }
    //     fetchClearanceData();
    // }, [currentClearance]);

    React.useEffect(() => {
        if (!isEmpty(selectedRow)) {
            if (selectedRow["clearanceList"] != null) {
                const index = selectedRow["clearanceList"].map(e => e.department).indexOf(props.currentUser["department"]);
                setNote(selectedRow["clearanceList"][index]['status'] == "Approved" ? selectedRow["clearanceList"][index]["note"] : "");
                setIsApproved(selectedRow["clearanceList"][index]['status'] == "Approved");
            }
        }
    }, [selectedRow])

    const getRowId = React.useCallback((params) => {
        const id = params.data.id;
        return id;
    }, []);

    function tConvert (time) {
        const [hourString, minute] = time.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }

    const getDate = (dateCreated) => {
        let date = new Date(dateCreated.seconds*1000);
        date = date.toString().split(" ")
        return <h1> {`${date[0]}, ${date[1]} ${date[2]}, ${date[3]} ${tConvert(date[4])}`}</h1>
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isApproved || note != "") {
            setUpdating(true);
            let newClearanceList = selectedRow["clearanceList"];
            const currentDate = new Date().toLocaleDateString("fr-CA");
            const index = newClearanceList.map(e => e.department).indexOf(props.currentUser["department"]);
            const initialClearanceData = initialClearance.filter(data => data.studentID ==  selectedRow["studentID"]);
            newClearanceList = initialClearanceData[0]["clearanceList"];
            newClearanceList[index] = {...newClearanceList[index], status: (isApproved ? "Approved" : "Pending"), note: note, date: currentDate}
            switch(newClearanceList[index]["status"]) {
                case "Pending":
                    axios.post(`${config.url}/upload/notifications/`, {name: "Your clearance request is marked as Pending by "+props.currentUser["name"], message: note, studentID: selectedRow["studentID"], isSeen: false}).then(() => {
                        setNotificationsData([...notificationsData, {name: "Your clearance request is marked as Pending by "+props.currentUser["name"], message: note, studentID: selectedRow["studentID"], isSeen: false}]);
                        // socket.emit("send_notification_data", notificationsData);
                    });
                    axios.get(`${config.url}/students`).then(el => {
                        const student = el.data.filter(e => e.id == selectedRow.studentID);
                        student.forEach(e => {
                            if (e.fCMToken != null) {
                                axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: "Your clearance request is marked as Pending by "+props.currentUser["name"], body: note})
                            }
                        })
                    })
                    break;
                case "Approved":
                    axios.post(`${config.url}/upload/notifications/`, {name: "Your clearance request is Approved by "+props.currentUser["name"], message: note, studentID: selectedRow["studentID"], isSeen: false}).then(() => {
                        setNotificationsData([...notificationsData, {name: "Your clearance request is Approved by "+props.currentUser["name"], message: note, studentID: selectedRow["studentID"], isSeen: false}]);
                        // socket.emit("send_notification_data", notificationsData);
                    });
                    axios.get(`${config.url}/students`).then(el => {
                        const student = el.data.filter(e => e.id == selectedRow.studentID);
                        student.forEach(e => {
                            if (e.fCMToken != null) {
                                axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: "Your clearance request is Approved by "+props.currentUser["name"], body: note})
                            }
                        })
                    })
                    break;
            }
            axios.post(`${config.url}/update/clearance/`+selectedRow["id"], initialClearanceData[0]["clearanceList"].length-1 == index ? {status: isApproved ? "Completed" : "Processing", "clearanceList": newClearanceList} : {"clearanceList": newClearanceList}).then(() => {
                setUpdating(false);
                const clearanceIndex = rowData.findIndex(index => {
                    return index.id == selectedRow["id"];
                });
                const newData = rowData;
                newData[clearanceIndex] = {...selectedRow, clearanceList: newClearanceList};
    
                if (newClearanceList[index]["status"] == "Approved") {
                    setRowData(newData.filter(data => newData[clearanceIndex].studentID != data.studentID));
                    setSelectedRow({});
                    // socket.emit("send_clearance_data", newData.filter(data => newData[clearanceIndex].studentID != data.studentID));
                } else {
                    setRowData(newData)
                    // socket.emit("send_clearance_data", newData);
                }
                setOpenEditModal(false);
                // socket.emit("send_clearance_list", clearance);
                // socket.emit("send_clearance_items", newClearanceList);
            })
        }
    }

    function isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
        return true;
    }
    
    return (
    <div>
        <div className="p-4 sm:ml-64 mt-16">
            <div className='w-full'>
                <h1 className='text-xl font-semibold py-4'>Clearance</h1>
                <hr className='border-black' />
            </div>

            <div className="relative w-72 py-5">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input onInput={(value) => {setSearchFilter(value.target.value)}} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
            </div>
            <div className='grid grid-cols-2 gap-5 h-[calc(100vh-18rem)]'>
                <div
                className="ag-theme-quartz h-auto"
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={20}
                        paginationPageSizeSelector={[100, 50, 20, 10]}
                        quickFilterText={searchFilter}
                        hideOVerlay={true}
                        rowSelection='single'
                        onSelectionChanged={() => {setSelectedRow(gridRef.current.api.getSelectedRows()[0]);}}
                        overlayNoRowsTemplate={isDoneFetching ? '<div>No data found.</div>' : '<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]"><svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg><span className="sr-only">Loading...</span></div>'}
                        getRowId={getRowId}
                    />
                </div>
                {!isEmpty(selectedRow) ? <div className='overflow-y-auto bg-gray-100'>
                    <div className='w-full p-6 flex flex-col gap-4 rounded-lg'>
                        <div className='flex items-center gap-4'>
                            <img className="w-10 h-10 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo" />
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>{selectedRow["fullName"]}</h1>
                                <p><span className='font-semibold'>Date Created:</span> {getDate(selectedRow["date"])}</p>
                            </div>
                        </div>

                        <div className='w-2/3 mx-auto'>
                            <h1 className='text-2xl'>Clearance Request</h1>
                            <h4 className='text-md'>Requested by <span className='font-semibold'>{selectedRow["fullName"]}</span></h4>
                            <h2 className='text-lg'>{selectedRow["purpose"]}</h2>
                            <div className='mt-4 flex gap-4'>
                                <button onClick={()=>{setIsApproved(true); setNote("")}} data-modal-hide="default-modal" type="button" className={`text-white ${isApproved?"bg-green-700":"bg-gray-500"} ${isApproved?"hover:bg-green-800":"hover:bg-gray-500"} focus:ring-4 focus:outline-none ${isApproved?"focus:green-red-300":"focus:ring-gray-200"} font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
                                    Approve
                                </button>
                                <button onClick={()=>{setIsApproved(false); setNote("")}} data-modal-hide="default-modal" type="button" className={`text-white ${!isApproved?"bg-orange-500":"bg-gray-500"} ${!isApproved?"hover:bg-orange-600":"hover:bg-gray-500"} focus:ring-4 focus:outline-none ${!isApproved?"focus:ring-orange-300":"focus:ring-gray-200"} font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
                                    Pending
                                </button>
                            </div>
                        </div>
                        {
                            isApproved ? <form onSubmit={handleSubmit} className='w-2/3 mx-auto'>
                                <h1 className='font-semibold text-lg mb-4'>Note</h1>
                                <textarea onChange={e => {setNote(e.target.value)}} required value={note} name="" id="" className="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">{note}</textarea>
                                <button  data-modal-hide="default-modal" type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    {
                                        updating ? <div role="status">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Submit"
                                    }
                                </button>
                            </form> 
                            :
                            <form onSubmit={handleSubmit} className='w-2/3 mx-auto'>
                                <h1 className='font-semibold text-lg mb-4'>Reason</h1>
                                <select name="note" required onChange={e => {setNote(e.target.value); e.target.value == "" ? setOtherReason(true) : setOtherReason(false)}} className="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option disabled selected></option>
                                    <option value="Outstanding Financial Dues">Outstanding Financial Dues</option>
                                    <option value="Unreturned Property">Unreturned Property</option>
                                    <option value="Incomplete Requirements">Incomplete Requirements</option>
                                    <option value="Policy Violations">Policy Violations</option>
                                    <option value="Damage to Property">Damage to Property</option>
                                    <option value="">Other</option>
                                </select>
                                {
                                    otherReason ? <div>
                                        <h1>Other reason:</h1>
                                        <textarea required onChange={e => {setNote(e.target.value)}} value={note} name="" id="" className="mb-4 bg-gray-50 border h-28 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                                    </div>
                                    :
                                    <div></div>
                                }
                                <button data-modal-hide="default-modal" type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    {
                                        updating ? <div role="status">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Submit"
                                    }
                                </button>
                            </form> 
                        }
                    </div> 
                    <div className='relative pb-5'>
                        {
                            selectedRow["clearanceList"].map((data, index) => {
                                const currentDate = Date.now();
                                let date = new Date(Date.parse(data.date));
                                let currentDateParse = new Date(currentDate).toString().split(" ")
                                let status = "bg-gray-500";
                                if(data["status"]) {
                                    switch(data["status"]) {
                                        case "Approved":
                                            status = "bg-blue-500";
                                            break;
                                        case "Pending":
                                            status = "bg-orange-500";
                                            break;
                                        default:
                                            status = "bg-gray-500";
                                    }
                                }
                                if ((index == 0 && data["status"] != "Approved") || (index != 0 && selectedRow["clearanceList"][index]["status"] != "Approved" && !data["status"] != "Approved")) {
                                    status = isApproved ? "bg-green-500" : "bg-orange-500";
                                }
                                date = date.toString().split(" ")
                                return (
                                    <div className='mb-5 relative z-20' key={index}>
                                        <div className={` w-28 p-2 ${status} text-white text-sm rounded-lg ${(index == 0 && data["status"] != "Approved") || (index != 0 && selectedRow["clearanceList"][index]["status"] != "Approved" && !data["status"] != "Approved") ? "border-4":""} border-blue-300 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                                        {(index == 0 && data["status"] != "Approved") || (index != 0 && selectedRow["clearanceList"][index]["status"] != "Approved" && !data["status"] != "Approved") ? `${currentDateParse[1]} ${currentDateParse[2]}, ${currentDateParse[3]}`:`${date[1]} ${date[2]}, ${date[3]}`}
                                        </div>
                                        <div className='ml-16 mt-2'>
                                            <h1 className='font-semibold'>
                                                {data["department"]}
                                            </h1>
                                            <p className='text-sm'>
                                                {data["note"]}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div className='border-l border-dashed border-blue-600 absolute left-10 top-0 h-full z-0'></div>
                        <div className='w-6 h-6 rounded-full bg-blue-600 absolute bottom-0 left-7 border-4 border-blue-300'></div>
                    </div>
                </div>: <div></div>}
            </div>
        </div>
    </div>
    )
}

export default ApproverClearance