import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import config from '../../config';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        title: {
            display: true,
            text: 'Document Statistic',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

function DocChart() {
    const [data, setData] = useState({
        labels: [],
        datasets: [
            { label: 'Pending', data: [], backgroundColor: 'rgb(255, 129, 38)' },
            { label: 'Declined', data: [], backgroundColor: 'rgb(255, 61, 61)' },
            { label: 'Processing', data: [], backgroundColor: 'rgb(53, 162, 235)' },
            { label: 'Ready for Pickup', data: [], backgroundColor: 'rgb(255, 206, 86)' },
            { label: 'Completed', data: [], backgroundColor: 'rgb(0, 191, 67)' },
            { label: 'Confirmed', data: [], backgroundColor: 'rgb(0, 191, 67)' },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                axios({
                    url: `${config.url}/credentials`,
                    method: "GET"
                })
                .then(response => {
                    const documents = response.data;
                
                    const statusCounts = {};
                
                    documents.forEach(doc => {
                        const status = doc.status;
                        const seconds = doc.date ? doc.date.seconds : 0;
                        const month = new Date(seconds * 1000).toLocaleString('default', { month: 'long', year: 'numeric' });
                
                        if (!statusCounts[status]) {
                            statusCounts[status] = {};
                        }
                        if (!statusCounts[status][month]) {
                            statusCounts[status][month] = 0;
                        }
                        statusCounts[status][month]++;
                    });
                
                    const allMonths = Object.keys(statusCounts)
                        .reduce((acc, status) => {
                            const statusMonths = Object.keys(statusCounts[status]);
                            return [...acc, ...statusMonths];
                        }, [])
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .sort((a, b) => new Date(a) - new Date(b));
                
                    const datasets = Object.keys(statusCounts).map(status => {
                        return {
                            label: status.charAt(0).toUpperCase() + status.slice(1),
                            data: allMonths.map(month => statusCounts[status][month] || 0),
                            backgroundColor: getStatusColor(status),
                        };
                    });
                
                    setData({
                        labels: allMonths,
                        datasets,
                    });
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
                
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending': return 'rgb(255, 129, 38)';
            case 'Declined': return 'rgb(255, 61, 61)';
            case 'Processing': return 'rgb(53, 162, 235)';
            case 'Ready for Pickup': return 'rgb(255, 206, 86)';
            case 'Completed': return 'rgb(0, 191, 67)';
            case 'Confirmed': return 'rgb(0, 191, 67)';
            default: return 'rgb(0, 0, 0)';
        }
    };

    return <Bar options={options} data={data} />;
}

export default DocChart;
