import React from 'react'
import Navbar from '../components/Navbar'
import SideBar from '../components/SideBar';
import DocChart from '../components/DocChart';
import axios from 'axios';
import { io } from 'socket.io-client';
import config from '../../config';
import { AgGridReact } from 'ag-grid-react';

function ApproverDashboard(props) {
    const [colDefs, setColDefs] = React.useState();
    const [rowData, setRowData] = React.useState([]);
    const [newReq, setNewReq] = React.useState(0);
    const [pending, setPending] = React.useState(0);
    const [completed, setCompleted] = React.useState(0);
    const [rejected, setRejected] = React.useState(0);
    const [isDoneFetching, setIsDoneFetching] = React.useState(false);

    // const socket = io.connect(config.url);

    React.useEffect(() => {
        setColDefs([
            { field: "studentNumber", headerName: "Student No.", maxWidth: 120 },
            { field: "fullName", headerName: "Full Name", flex: 1},
            { field: "purpose", headerName: "Purpose", flex: 1},
            { field: "status", headerName: "Status", cellStyle: {"text-transform": "capitalize"}, flex: 1,},
            { field: "date", headerName: "Date", flex: 1, 
                cellRenderer: props => {
                    if (props.value != null) {
                        let date = new Date(props.value.seconds*1000);
                        date = date.toString().split(" ")
                        return <h1> {`${date[1]} ${date[2]}, ${date[3]}`}</h1>
                    } else {
                        return <h1></h1>
                    }
                },
            },
        ]);

        axios({
            url: `${config.url}/clearance`,
            method: "GET"
        })
        .then(data => {
            let completedCount = 0;
            let newCount = 0;
            let rejectedCount = 0;
            let pendingCount = 0;
            let rawData = data.data.filter(e => e["clearanceList"] && e["clearanceList"].some(data => data["department"] == props.currentUser["department"] && (data["approver"] == props.currentUser["name"] || data["approver"] == null || !data["approver"]))).map(el => {
                const index = el["clearanceList"].map(e => e.department).indexOf(props.currentUser["department"]);
                if (getDate(el["date"].seconds*1000) == getDate(Date.now())) {
                    newCount++;
                }
                switch(el["clearanceList"][index]["status"]) {
                    case "Approved":
                        completedCount++;
                        break;
                    case "Declined":
                        rejectedCount++;
                    default:
                        pendingCount++;
                }
                
                let newClearanceList = [];
                el["clearanceList"].forEach((e, i) => {
                    if (index >= i) {
                        newClearanceList.push(e);
                    }
                });
                if ((newClearanceList.length == 1 && index == 0) || (index != 0 && newClearanceList[index-1]["status"] == "Approved")) {
                    return {...el, clearanceList: newClearanceList};
                }
                return null;
            }).filter(data => data != null && data["clearanceList"].some(e => e["department"] == props.currentUser["department"] && e["status"] != "Approved"));
            setNewReq(newCount);
            setCompleted(completedCount);
            setRejected(rejectedCount);
            setPending(pendingCount)
            setRowData(rawData)
            setIsDoneFetching(true);
        })
    }, [])

    const getDate = (millis) => {
        let date = new Date(millis);
        date = date.toString().split(" ")
        return `${date[1]} ${date[2]}, ${date[3]}`
    }

    // React.useEffect(() => {
    //     socket.on("clearance_data_change", (data) => {
    //         setRowData(data);
    //     })
    // }, [socket])
    
    
    return (
        <div>
            {/* <Navbar></Navbar>
            <SideBar loc='Dashboard'></SideBar> */}
            <div className="p-4 sm:ml-64 mt-16">
                <div className='w-full'>
                    <h1 className='text-xl font-semibold py-4'>Dashboard</h1>
                    <hr className='border-black' />
                </div>
                <div className="mt-5">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-blue-400 dark:bg-gray-800 text-white">
                            <svg className="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between w-auto'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>New Requests</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{newReq}</h1>
                            </div>
                        </div>
                        <div className="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-orange-400 dark:bg-gray-800 text-white">
                            <svg className="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Pending Requests</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{pending}</h1>
                            </div>
                        </div>
                        <div className="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-green-400 dark:bg-gray-800 text-white">
                            <svg className="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Completed Requests</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{completed}</h1>
                            </div>
                        </div>
                        <div className="flex items-center flex-col lg:flex-row md:flex-row justify-between lg:justify-center md:justify-center lg:gap-10 md:gap-10 p-5 gap-2 h-44 rounded bg-red-400 dark:bg-gray-800 text-white">
                            <svg className="flex-shrink-0 w-20 h-5 sm:w-5 sm:h-5 md:h-10 md:w-10 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>
                            <div className='flex flex-col items-center lg:items-start md:items-start justify-between'>
                                <h1 className='font-semibold sm:text-sm text-center lg:text-start md:text-start md:text-md lg:text-2xl'>Rejected Requests</h1>
                                <h1 className='text-4xl sm:text-md md:text-lg lg:text-4xl'>{rejected}</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className="ag-theme-quartz h-[calc(100vh-18rem)]">
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            pagination={true}
                            paginationPageSize={20}
                            paginationPageSizeSelector={[100, 50, 20, 10]}
                            hideOVerlay={true}
                            overlayNoRowsTemplate={isDoneFetching ? '<div>No data found.</div>' : '<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]"><svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg><span className="sr-only">Loading...</span></div>'}
                            />
                    </div>
                    
                </div>
                </div>
        </div>
    )
}

export default ApproverDashboard