import React from 'react'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import booleanRenderer from "../components/booleanRenderer.jsx";
import actionButtons from "../components/actionButtons.jsx";
import axios from 'axios';
import { io } from 'socket.io-client';
import { AnimatePresence, Reorder } from 'framer-motion';
import config from '../../config.js';

function Clearance(props) {
    const [rowData, setRowData] = React.useState([]);
    const [searchFilter, setSearchFilter] = React.useState("");
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const gridRef = React.useRef();
    const [updating, setUpdating] = React.useState(false);
    const [colDefs, setColDefs] = React.useState();
    const [items, setItems] = React.useState([12345]);
    const [clearance, setClearance] = React.useState([{id: 12345}]);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [openBulkDeleteModal, setOpenBulkDeleteModal] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [currentClearance, setCurrentClearance] = React.useState("");
    const [clearanceData, setClearanceData] = React.useState({});
    const [notifConfig, setNotifConfig] = React.useState([]);
    const [notificationsData, setNotificationsData] = React.useState([]);
    const [accounts, setAccounts] = React.useState([]);
    const [templateCheckbox, setTemplateCheckbox] = React.useState([]);
    const [clearanceTemplates, setClearanceTemplates] = React.useState([]);
    const [isDoneFetching, setIsDoneFetching] = React.useState(false);
    const [openDropdown, setOpenDropdown] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [openTemplateModal, setOpenTemplateModal] = React.useState(false);
    const [markAsCompleted, setMarkAsCompleted] = React.useState(false);
    const [templateName, setTemplateName] = React.useState("");
    // const socket = io.connect(config.url);

    React.useEffect(() => {
        axios({
            url: `${config.url}/clearance`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            rawData.forEach(newData => {
                let clearanceList = newData.clearanceList;
                if (clearanceList != null) {
                    const newItemList = [];
                    clearanceList.forEach(data => {
                        newItemList.push(data.id)
                    })
                    setItems(newItemList);
                    setClearance(clearanceList);
                }
                rawData.id = data.id;
            })
            setRowData(rawData)
            setIsDoneFetching(true);
        })

        axios({
            url: `${config.url}/notifConfig`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotifConfig(rawData)
        })

        axios({
            url: `${config.url}/accounts`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setAccounts(rawData)
        })

        axios({
            url: `${config.url}/notifications`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setNotificationsData(rawData)
        })

        axios({
            url: `${config.url}/clearanceTemplate`,
            method: "GET"
        })
        .then(data => {
            let rawData = data.data;
            setClearanceTemplates(rawData)
        })
    }, [])

    React.useEffect(() => {
        setColDefs([
            { 
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 40
            },
            { field: "studentNumber", headerName: "Student No.", maxWidth: 120 },
            { field: "fullName", headerName: "Full Name", flex: 1},
            { field: "purpose", headerName: "Purpose", flex: 1},
            { field: "status", headerName: "Status", cellStyle: {"text-transform": "capitalize"}, flex: 1,},
            { field: "date", headerName: "Date", flex: 1, 
                cellRenderer: props => {
                    if (props.value != null) {
                        let date = new Date(props.value.seconds*1000);
                        date = date.toString().split(" ")
                        return <h1> {`${date[1]} ${date[2]}, ${date[3]}`}</h1>
                    } else {
                        return <h1></h1>
                    }
                },
            },
            { field: "id", headerName: "Actions", 
            cellRenderer: props => {
                return (
                    <div className="flex items-center gap-1 w-full h-full">
                        <button type="button" onClick={() => {setOpenEditModal(true); setCurrentClearance(props.value)}} className="w-8 h-8 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" fill-rule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"></path> </g></svg>
                        </button>
                        <button type="button" onClick={() => {setCurrentClearance(props.value); setOpenDeleteModal(true)}} className="w-8 h-8 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </button>
                    </div>
                )
            }
        },
        ])
        
    }, [rowData])

    // React.useEffect(() => {
    //     socket.on("clearance_data_change", (data) => {
    //         setRowData(data);
    //     })
    //     socket.on("clearance_list_change", (data) => {
    //         setClearance(data);
    //     })
    //     socket.on("clearance_items_change", (data) => {
    //         setItems(data);
    //     })
    // }, [socket]);

    React.useEffect(() => {
        const fetchClearanceData = () => {
            const clearanceData = rowData.filter(el => {
                return el.id == currentClearance;
            })
            setClearanceData(clearanceData[0] != null ? clearanceData[0] : {});
            setClearance(clearanceData[0] != null ? clearanceData[0]["clearanceList"] : []);
            setItems(clearanceData[0] != null ? clearanceData[0]["clearanceList"].map(data => data.id) : []);
        }
        fetchClearanceData();
    }, [currentClearance]);

    const getRowId = React.useCallback((params) => {
        const id = params.data.id;
        return id;
    }, []);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedClearance = [...clearance];
        updatedClearance[index] = { ...updatedClearance[index], [name]: value };
        setClearance(updatedClearance);
        // if (name == "department") {
        //     let depOpt = updatedClearance;
        //     let departments = [
        //         "Computer Laboratory",
        //         "Facilities Office",
        //         "Library",
        //         "Department (DAS/DCS)",
        //         "Guidance unit",
        //         "Discipline unit",
        //         "Accounting",
        //     ]
        //     depOpt.map(data => {
        //         departments = departments.filter(dep => dep != data.department)
        //     })
        //     setDepartmentOptions(departments);
        // }
    };

    const handleTemplateChange = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setTemplateCheckbox([...templateCheckbox, value]);
        } else {
            setTemplateCheckbox(templateCheckbox.filter(data => data != value));
        }
    };

    const deleteTemplates = () => {
        templateCheckbox.forEach(template => {
            axios.post(`${config.url}/delete/clearanceTemplate/${template}`)
            .catch((error) => {
                console.error('Error deleting templates:', error);
            });
        })
        const remainingData = clearanceTemplates.filter(row => !templateCheckbox.some(data => row.id == data));
        setClearanceTemplates(remainingData);
    }

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        setClearanceData({...clearanceData, [name]: value});
    };

    const saveTemplate = () => {
        setSaving(true);
        axios.post(`${config.url}/upload/clearanceTemplate`, {name: templateName, list: clearance})
        .then(clearanceTemp => {
            setTemplateName("");
            setSaving(false);
            setOpenTemplateModal(false);
            setClearanceTemplates(clearanceTemp.data);
        })
        .catch((error) => {
            console.error('Error deleting templates:', error);
        });
    }

    const handleReorder = (e) => {
        const newClearance = [];
        e.forEach(item => {
            const newItem = clearance.filter(filterItem => {
                return filterItem.id == item;
            });
            newClearance.push(newItem[0]);
        })
        setClearance(newClearance)
        setItems(e);
        console.log(newClearance);
        console.log(e)
    }

    const deleteSelectedData = () => {
        setDeleting(true);
        axios.post(`${config.url}/bulk-delete`, {collection: "clearance", ids: selectedRows} )
        .then(() => {
            const remainingData = rowData.filter(row => !selectedRows.some(e => e.id == row.id));
            setRowData(remainingData);
            // socket.emit("send_clearance_data", remainingData);
            setDeleting(false);
            setOpenBulkDeleteModal(false);
        })
        .catch((error) => {
            console.error('Error deleting students:', error);
        });
    };

    const deleteData = (e) => {
        e.preventDefault();
        setDeleting(true);
        axios.post(`${config.url}/delete`, {collection: "clearance", id: currentClearance})
        .then(() => {
            const remainingData = rowData.filter(row => row.id != currentClearance);
            setRowData(remainingData);
            // socket.emit("send_clearance_data", remainingData);
            setDeleting(false);
            setOpenDeleteModal(false);
        })
        .catch((error) => {
            console.error('Error deleting clearances:', error);
        });
    }

    const saveChanges = (id, clearance) => {
        setUpdating(true);
        notifConfig.filter(data => data.status.toLowerCase() == "active" && data.type == "Clearance Request Processing").forEach(data => {
            axios.post(`${config.url}/upload/notifications/`, {name: data.name, message: data.message, studentID: clearanceData["studentID"], isSeen: false}).then(() => {
                setNotificationsData([...notificationsData, {name: data.name, message: data.message, studentID: clearanceData["studentID"], isSeen: false}]);
                // socket.emit("send_notification_data", notificationsData);
            });
            axios.get(`${config.url}/students`).then(el => {
                const student = el.data.filter(e => e.id == clearanceData.studentID);
                student.forEach(e => {
                    if (e.fCMToken != null) {
                        axios.post(`${config.url}/send-notification`, { fcmToken: e.fCMToken, title: data.name, body: data.message})
                    }
                })
            })
        });
        if (markAsCompleted) {
            clearance = clearance.map((data, index) => {
                data['status'] = "Approved";
                data['date'] = new Date().toLocaleDateString("fr-CA");
                return data;
            });
        }
        axios.post(`${config.url}/update/clearance/`+id, {"status": markAsCompleted ? "Completed" : (clearance.length == 0 ? "Pending":"Processing"), "clearanceList": clearance}).then(() => {
            setUpdating(false);
            const index = rowData.findIndex(index => {
                return index.id == id;
            });
            const newData = rowData;
            newData[index] = {...newData[index], "status": markAsCompleted ? "Completed" : (clearance.length == 0 ? "Pending":"Processing"), "clearanceList": clearance};
            setRowData(newData);
            setOpenEditModal(false);
            setMarkAsCompleted(false);
            // socket.emit("send_clearance_data", newData);
            // socket.emit("send_clearance_list", clearance);
            // socket.emit("send_clearance_items", items);
        })
    }
    
    return (
    <div>
        <div className="p-4 sm:ml-64 mt-16">
            <div className='w-full'>
                <h1 className='text-xl font-semibold py-4'>Clearance</h1>
                <hr className='border-black' />
            </div>

            <div className='flex items-center justify-between'>
                <div className="relative w-72 py-5">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input onInput={(value) => {setSearchFilter(value.target.value)}} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                </div>

                <div className={`${selectedRows.length > 0 ? 'flex': 'hidden'} gap-1`}>
                    <button onClick={() => setOpenBulkDeleteModal(true)} className="p-2 w-20 bg-red-600 text-white rounded">Delete</button>
                </div>

                <div></div>
            </div>

            <div
            className="ag-theme-quartz h-[calc(100vh-18rem)]"
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={[100, 50, 20, 10]}
                    quickFilterText={searchFilter}
                    hideOVerlay={true}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    onSelectionChanged={() => {setSelectedRows(gridRef.current.api.getSelectedRows())}}
                    overlayNoRowsTemplate={isDoneFetching ? '<div>No data found.</div>' : '<div role="status" className="absolute top-[50%] left-[50%] z-[100] translate-x-[-50%] translate-y-[-50%]"><svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg><span className="sr-only">Loading...</span></div>'}
                    getRowId={getRowId}
                />
            </div>
        </div>
        <div id="default-modal" tabindex="-1" aria-hidden="true" className={(!openEditModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button type='button' className='all-unset cursor-default w-full h-full fixed left-0 top-0 bg-black opacity-30' onClick={() => {setOpenEditModal(false); setOpenDropdown(false); setTemplateCheckbox([]);}}></button>
            <div className="relative p-4 w-full max-w-3xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <form>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Clearance Request
                            </h3>
                            <button onClick={() => {setOpenEditModal(false); setOpenDropdown(false); setTemplateCheckbox([]);}} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div className={`flex flex-col items-center gap-2 w-full`}>
                                <button type='button' onClick={() =>  {setOpenDropdown(false); setTemplateCheckbox([]);}} className={`${!openDropdown ? "hidden" : ""} cursor-default absolute w-full h-full z-[9]`}></button>
                                <div className='flex justify-end items-center gap-2 w-full'>
                                    <div className='relative'>
                                        <button onClick={() => setOpenDropdown(!openDropdown)} id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Select template <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                            </svg>
                                        </button>

                                        <div id="dropdownSearch" class={`${!openDropdown ? "hidden" : ""} z-[10] bg-white rounded-lg shadow w-60 dark:bg-gray-700 absolute left-0`}>
                                            
                                            <ul class="max-h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 pt-2" aria-labelledby="dropdownSearchButton">
                                                {
                                                    clearanceTemplates.length > 0 ? clearanceTemplates.map(template => {
                                                        return (
                                                            <li>
                                                                <div class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 w-full">
                                                                    <input type="checkbox" checked={templateCheckbox.includes(template.id)} value={template.id} onChange={handleTemplateChange} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                    <button onClick={() => {setOpenDropdown(false); setTemplateCheckbox([]); setClearance(template['list']); setItems(template['list'].map(clearanceID => clearanceID["id"]))}} type='button' className='w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300 flex items-start'>{template['name']}</button>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) 
                                                    :
                                                    <li>
                                                        <div class="flex items-center justify-center ps-2 rounded w-full">
                                                            <h1>No templates found.</h1>
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                            {
                                                clearanceTemplates.length > 0 ? <button onClick={deleteTemplates} type='button' class="flex items-start w-full p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline">
                                                    Delete template
                                                </button> : <div></div>
                                            }
                                        </div>
                                    </div>

                                    <button onClick={()=>setOpenTemplateModal(true)} data-modal-hide="default-modal" type="button" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                        Save as template
                                    </button>

                                </div>
                                <Reorder.Group values={items} onReorder={e => handleReorder(e)} className='flex flex-col gap-8 w-full'>
                                    {
                                        items.map((data, index) => {
                                            let depOpt = clearance;
                                            let departments = [
                                                "Computer Laboratory",
                                                "Facilities Office",
                                                "Library",
                                                "Department (DAS/DCS)",
                                                "Guidance unit",
                                                "Discipline unit",
                                                "Accounting",
                                            ]
                                            depOpt.map(deps => {
                                                departments = departments.filter(dep => dep != deps.department || dep == clearance[index].department)
                                            })
                                            return (
                                            <AnimatePresence key={data} initial={false}>
                                                <Reorder.Item value={data}>
                                                    <div className='flex items-center gap-4 relative'>
                                                        <div className='w-4 h-4 rounded-full bg-blue-600'></div>
                                                        {
                                                            (index == 0 && items.length > 1) || (items.length > 1 && items.length != index+1) ? <div className='border-l border-dashed border-blue-600 absolute left-2 top-[50%] h-32'></div>
                                                            : null
                                                        }
                                                        <div className='flex items-center p-5 gap-4 relative bg-slate-200 rounded-lg flex-1 pr-20'>
                                                            <button onClick={() => {
                                                                setItems(items.filter(e=> e != data))
                                                                setClearance(clearance.filter(e=> e.id != data))
                                                            }} type="button" className="w-8 h-8 absolute top-5 right-5 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                                                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                                            </button>
                                                            <div className='flex flex-col items-center flex-1'>
                                                                <h1>Select Department</h1>
                                                                <select name="department" required onChange={e => handleChange(e, index)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                    <option disabled selected={!clearance[index].department}></option>
                                                                    {
                                                                        departments.map(dep => <option value={dep} selected={dep == clearance[index].department}>{dep}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className='flex flex-col items-center flex-1'>
                                                                <h1>Select Approver</h1>
                                                                <select name="approver" required onChange={e => handleChange(e, index)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                    <option disabled selected={clearance[index].approver == null || !clearance[index].approver}></option>
                                                                    {
                                                                        accounts.filter(data => data.department == clearance[index].department).map(acc => <option value={acc.name} selected={clearance[index].approver != null && acc.name == clearance[index].approver}>{acc.name}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className='flex flex-col items-center flex-1'>
                                                                <h1>Note</h1>
                                                                <input value={clearance[index].note} onChange={e => handleChange(e, index)} type="text" name="note" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Reorder.Item>
                                            </AnimatePresence>
                                            )
                                        })
                                    }
                                </Reorder.Group>
                                <button type="button" className={`${items.length < 7 ? "":"hidden"}`} onClick={() => {
                                    const randomID = Math.floor(Math.random()*1000000000)
                                    setItems([...items, randomID])
                                    let newClearance = [...clearance, {id: randomID}];
                                    setClearance(newClearance)
                                }}>
                                    <svg className='w-8 h-8' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" > <g id="SVGRepo_bgCarrier" stroke-width={0} /> <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" /> <g id="SVGRepo_iconCarrier"> {" "} <title>plus-circle</title> <desc>Created with Sketch Beta.</desc>{" "} <defs> </defs>{" "} <g id="Page-1" stroke="none" stroke-width={1} fill="none" fill-rule="evenodd" > {" "} <g id="Icon-Set-Filled" transform="translate(-466.000000, -1089.000000)" fill="#141414" > {" "} <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" > {" "} </path>{" "} </g>{" "} </g>{" "} </g> </svg>
                                </button>
                            </div>

                        </div>
                        <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                            <div class={(props.currentUser["type"] != "Administrator" ? "hidden" : "")+" flex items-center mb-4"}>
                                <input id="default-checkbox" checked={markAsCompleted} onChange={() => setMarkAsCompleted(!markAsCompleted)} type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mark as Completed</label>
                            </div>
                            <div className='flex items-center gap-2'>
                                <button onClick={() => setOpenEditModal(false)} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                                <button onClick={()=>saveChanges(currentClearance, clearance)} data-modal-hide="default-modal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    
                                    {
                                        updating ? <div role="status">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Save Changes"
                                    }
                                    
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteData} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openBulkDeleteModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => setOpenBulkDeleteModal(false)}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setOpenBulkDeleteModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this account?</h3>
                        <button onClick={deleteSelectedData} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                deleting ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Delete"
                            }
                        </button>
                        <button onClick={() => setOpenBulkDeleteModal(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="popup-modal" tabindex="-1" className={(!openTemplateModal ? "hidden" : "")+" overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full"}>
            <button className='all-unset cursor-default w-full h-full absolute left-0 top-0 bg-black opacity-30' onClick={() => {setOpenTemplateModal(false); setTemplateName("")}}></button>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => {setOpenTemplateModal(false); setTemplateName("")}} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <div className='py-5 flex flex-col items-start justify-center'>
                            <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Template Name</label>
                            <input type='text' value={templateName} onChange={e => setTemplateName(e.target.value)} id="name" name='name' className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <button onClick={() => {setOpenTemplateModal(false); setTemplateName("")}} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                        <button onClick={saveTemplate} data-modal-hide="popup-modal" type="button" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                saving ? <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span className="sr-only">Loading...</span>
                                </div> : "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    )
}

export default Clearance