import React from 'react'
import axios from 'axios';
import ImageResize from 'quill-image-resize-module--fix-imports-error';
import {ImageDrop} from 'quill-image-drop-module';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import config from '../../config';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);
const Size = Quill.import("attributors/style/size");
Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
Quill.register(Size, true);
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
    add(node, value) {
        if (value === 0) {
        this.remove(node);
        return true;
        } else {
        return super.add(node, `${value}em`);
        }
    }
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
    scope: Parchment.Scope.BLOCK,
    whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});
Quill.register(new Parchment.Attributor.Style('display', 'display', { 
    whitelist: ['inline']
}));
Quill.register(new Parchment.Attributor.Style('float', 'float', { 
    whitelist: ['left', 'right', 'center']
}));
Quill.register(new Parchment.Attributor.Style('margin', 'margin', {}));

Quill.register(IndentStyle, true);

function InfoHub() {
    const [editorData, setEditorData] = React.useState("");
    const [infoID, setInfoID] = React.useState("YT1Coq6wmlH34XIwwtdI");
    const quillRef = React.useRef(null);

    const handleChange = (html) => {
        setEditorData(html);
    };

    const modules = React.useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': []}, { 'font': [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'align': [] }],
                    ['image'],
                    ['clean']
                ],
                handlers: {
                    'image': imageHandler
                }
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize', 'Toolbar']
            },
            imageDrop: true
        }),
        []
    );

    function imageHandler(param) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        console.log(param)
    
        input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('upload', file);
    
        const response = await axios.post(`${config.url}/upload-image`, formData).then(data => {
            return data.data.url
        });
    
        const url = response;
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, 'image', url);
        };
    }

    React.useEffect(() => {
        try {
            axios.get(`${config.url}/info-hub`).then(data => {
                setInfoID(data.data[0].id);
                setEditorData(data.data[0].content)
            });
        } catch(err) {
            console.error(err)
        }
    }, []);

    const saveContentToBackend = () => {
        try {
            axios.post(`${config.url}/update/info-hub/`+infoID, {
                content: editorData
            });
            alert('Content saved successfully!');
        } catch (error) {
            console.error('Error saving content:', error);
        }
    };

    return (
        <div className="p-4 sm:ml-64 mt-16">
            <div className='w-full pb-4'>
                <h1 className='text-xl font-semibold py-4'>Info Hub</h1>
                <hr className='border-black' />
            </div>
            <ReactQuill
                ref={quillRef} 
                value={editorData}
                onChange={handleChange}
                modules={modules}
                bounds={`[data-text-editor="form-editor"]`}
            />
            <div className='flex justify-between w-full'>
                <div></div>
                <button onClick={saveContentToBackend} data-modal-hide="default-modal" type="button" className="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Save
                </button>
            </div>
        </div>
    )
}

export default InfoHub