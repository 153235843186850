import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC7cd7QoYR4hZ1Oocnvc4qg2rmB4xWN3t0",
    authDomain: "tamatrack-8e92c.firebaseapp.com",
    projectId: "tamatrack-8e92c",
    storageBucket: "tamatrack-8e92c.appspot.com",
    messagingSenderId: "720472942540",
    appId: "1:720472942540:web:9d4b8b5c25da8894531f69",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;
